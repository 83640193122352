import React, { useState } from 'react';
import dtg_smm_img_2 from './Assets/dtg_smm_img_2.png';
import dtg_smm_img_3 from './Assets/dtg_smm_img_3.png';
import dtg_sem_img_4 from './Assets/dtg_sem_img_4.png';
import strategic_content_creation from './Assets/strategic_content_creation.png';
import targeted_advertising from './Assets/targeted_advertising.png';
import comprehensive_platform_management from './Assets/comprehensive_platform_management.png';
import audience_engagement from './Assets/audience_engagement.png';
import analytics_and_reporting from './Assets/analytics_and_reporting.png';
import crisis_management from './Assets/crisis_management.png';
import influencer_collaboration from './Assets/influencer_collaboration.png';
import dtg_smm_img_5 from './Assets/dtg_smm_img_5.png';
import dtg_smm_img_6 from './Assets/dtg_smm_img_6.png';
import concept_development from './Assets/concept_development.png';
import scriptwriting_storyboarding from './Assets/scriptwriting_storyboarding.png';
import professional_filming from './Assets/professional_filming.png';
import creative_editing from './Assets/creative_editing.png';
import music_sound_design from './Assets/music_sound_design.png';
import branding_actions from './Assets/branding_actions.png';
import platform_optimization from './Assets/platform_optimization.png';
import eye_catching_graphics from './Assets/eye_catching_graphics.png';
import message_clarity from './Assets/message_clarity.png';
import brand_integration from './Assets/brand_integration.png';
import print_and_digital_formats from './Assets/print_and_digital_formats.png';
import professional_quality from './Assets/professional_quality.png';
import whatapp_logo from './Assets/whatapp_logo.png';
import instagram_logo from './Assets/instagram_logo.png';
import x_logo from './Assets/x_logo.png';
import facebook_logo from './Assets/facebook_logo.png';
import picarts_logo from './Assets/picarts_logo.png';
import pinterest_logo from './Assets/pinterest_logo.png';
import ds_logo from './Assets/ds_logo.png';
import capcut_logo from './Assets/capcut_logo.png';
import filmora_logo from './Assets/filmora_logo.png';
import photoshop_logo from './Assets/photoshop_logo.png';

const SocialMediaMarketing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full min-h-screen bg-white">
      {/* Main Title Section */}
      <div className="flex flex-col items-center justify-center text-center mt-8 px-4">
        <h2 className="text-3xl font-bold mb-4">Social Media Marketing (SMM)</h2>
        <p className="max-w-4xl text-lg mb-8">
          In today's digital landscape, social media is where your customers are—and where your brand needs to be. At PCL Infotech, our Social Media Marketing services are designed to help you engage your audience, build a strong brand presence, and drive targeted traffic to your website.
        </p>
      </div>

      {/* Service Images and Description */}
      <div className="flex flex-col md:flex-row items-center justify-center gap-2 mb-38 px-4">
        <img src={dtg_smm_img_2} alt="SMM Service 1" className="w-full md:w-1/3 h-auto" />
        <p className="text-lg text-center md:text-left max-w-2xl">
          Looking to boost your social media presence and engage with your target audience effectively? Our Social Media Marketing services at PCL Infotech are designed to help your business achieve just that.
        </p>
        <img src={dtg_smm_img_3} alt="SMM Service 2" className="w-full md:w-1/3 h-auto" />
      </div>

      {/* CTA Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-white text-black px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          Build my Social Media Marketing (SMM) Services
        </button>
      </div>

      {/* Services Description */}
      <div className="text-center px-4 mt-12 max-w-4xl mx-auto">
        <p className="text-lg">
          We manage every aspect of your social media strategy, from content creation and targeted advertising to community management, ensuring that your brand shines across platforms like Facebook, Instagram, Twitter, LinkedIn, and more.
        </p>
      </div>

      {/* Transform Section */}
      <div className="text-center px-4 mt-16">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          Transform Your Online Business with PCL Infotech's Social Media Marketing (SMM)
        </h2>
        <p className="max-w-4xl mx-auto text-lg">
          At PCL Infotech, we're committed to helping you build a strong social media presence that not only meets your current needs but also supports your future growth. Let us be your partner in creating engaging content that converts followers into loyal customers and drives sustained business success.
        </p>
      </div>

      {/* Why Choose Us Section */}
      <div className="text-center mt-16">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 px-4">
          <img src={dtg_sem_img_4} alt="Why Choose Us" className="w-64 h-auto" />
          <h2 className="text-2xl font-bold">Why Choose PCL Infotech for Your Social Media Marketing (SMM) Services Needs</h2>
        </div>
      </div>

      {/* Service Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mt-16 max-w-7xl mx-auto">
        {/* Card 1 - Strategic Content Creation */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={strategic_content_creation} alt="Strategic Content Creation" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Strategic Content Creation</h3>
          <p className="text-center">
            We craft engaging, relevant, and visually appealing content that resonates with your audience and drives engagement.
          </p>
        </div>

        {/* Card 2 - Targeted Advertising */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={targeted_advertising} alt="Targeted Advertising" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Targeted Advertising</h3>
          <p className="text-center">
            Our SMM experts develop targeted ad campaigns that reach the right audience at the right time for maximum impact.
          </p>
        </div>

        {/* Card 3 - Platform Management */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={comprehensive_platform_management} alt="Platform Management" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Platform Management</h3>
          <p className="text-center">
            We manage your presence across all major social media platforms, ensuring consistent branding and messaging.
          </p>
        </div>

        {/* Card 4 - Audience Engagement */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={audience_engagement} alt="Audience Engagement" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Audience Engagement</h3>
          <p className="text-center">
            We actively engage with your audience, building a community around your brand and fostering loyalty.
          </p>
        </div>

        {/* Card 5 - Analytics & Reporting */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={analytics_and_reporting} alt="Analytics & Reporting" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Analytics & Reporting</h3>
          <p className="text-center">
            Regular performance reports and analytics insights to help optimize your social media strategy.
          </p>
        </div>

        {/* Card 6 - Crisis Management */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={crisis_management} alt="Crisis Management" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Crisis Management</h3>
          <p className="text-center">
            Proactive monitoring and quick response to potential issues to protect your brand reputation.
          </p>
        </div>
      </div>

      {/* Contact Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          CONTACT US
        </button>
      </div>

      {/* Reel Creation Section */}
      <div className="mt-24 px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Reel Creation Services</h2>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center p-6 gap-4 mt-8">
          <img src={dtg_smm_img_5} alt="Reel Creation 1" className="w-full md:w-1/4 h-auto" />
          <p className="text-xl text-center font-semibold">
            Professional Reel Creation Services for Enhanced Social Media Marketing
          </p>
          <img src={dtg_smm_img_6} alt="Reel Creation 2" className="w-full md:w-1/3 h-auto" />
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Technology Stack We Use for Social Media Marketing
          </h2>
          <p className="text-lg mb-12">
            Our team leverages industry-leading tools and platforms to create and manage your social media presence effectively.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-8 items-center justify-items-center">
            {[
              whatapp_logo,
              instagram_logo,
              x_logo,
              facebook_logo,
              picarts_logo,
              pinterest_logo,
              ds_logo,
              capcut_logo,
              filmora_logo,
              photoshop_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default SocialMediaMarketing;