import React from 'react';
import business_operations from '../Assets/home/Business-Operations-IT-Service.webp'
import customer_marketing from '../Assets/home/Customer-Marketing-IT-Service.webp'
import enterprise_technology_performance from '../Assets/home/Customer-Marketing-IT-Service.webp'
import strategy_analytics from '../Assets/home/Strategy-Analytics-IT-Service.webp'
import product_design from '../Assets/home/Product-design-IT-Service.webp'
import management from '../Assets/home/Management-IT-Service.webp'

const ConsultantCard = ({ image, alt, title, heading, description }) => (
  <div className="flex flex-col justify-center items-center w-full md:w-[400px] p-6 gap-3 rounded-lg border border-[#004aad] shadow-lg hover:transform hover:scale-105 transition duration-300 cursor-pointer">
    <img src={image} alt={alt} title={title} className="w-6 h-6 object-contain" />
    <h3 className="text-xl font-bold text-center">{heading}</h3>
    <p className="text-base font-medium text-center">{description}</p>
  </div>
);

const Consultant = () => {
  const services = [
    {
      image: business_operations,
      alt: "A coin icon placed in an eye, symbolizing financial insight in IT services.",
      title: "IT Service Financial Insight Representation",
      heading: "Business Operations",
      description: "Streamline your operations with our business consulting services. We help you identify inefficiencies, implement strategies that improve productivity, and reduce costs."
    },
    {
      image: customer_marketing,
      alt: "Blue pixel icon encircled, representing IT services and digital solutions.",
      title: "Blue Pixel Icon for IT Service",
      heading: "Customer Marketing",
      description: "Enhance your customer experience and marketing efforts with our consulting services. We provide insights and strategies that help you attract, engage, and retain customers."
    },
    {
      image: enterprise_technology_performance,
      alt: "Blue icon featuring a bar chart, symbolizing data analysis in IT services.",
      title: "Blue Icon Featuring Bar Chart for IT Service",
      heading: "Enterprise Technology Performance",
      description: "Optimize your IT infrastructure and improve performance with our enterprise technology consulting. We help you implement the right technologies to drive efficiency and growth."
    },
    {
      image: strategy_analytics,
      alt: "Blue icon featuring a map, symbolizing IT service navigation and support",
      title: "Blue Icon Featuring Map for IT Service Navigation",
      heading: "Strategy Analytics",
      description: "Make data-driven decisions with our strategy and analytics consulting services. We help you analyze data, identify trends, and develop strategies that align with your business goals."
    },
    {
      image: product_design,
      alt: "A blue square featuring a smaller square at its center, symbolizing IT service solutions and digital innovation.",
      title: "Blue Square Representing IT Service Solutions",
      heading: "Product Design",
      description: "Bring your ideas to life with our product design consulting services. We help you design and develop products that meet customer needs and stand out in the market."
    },
    {
      image: management,
      alt: "Blue icon depicting a person in front of a stack of papers, symbolizing IT service and document management.",
      title: "Blue Icon Depicting Document Management for IT Service",
      heading: "Management",
      description: "Our experts work closely with you to understand your business challenges and develop solutions that deliver measurable results."
    }
  ];

  return (
    <div className="flex flex-col items-center gap-5 mt-16 px-4 md:px-6 lg:px-8">
      <h2 className="text-2xl md:text-3xl font-extrabold text-[#004aad]">
        Your Goals <span className="text-[#004aad]">Our Vision</span>
      </h2>
      
      <p className="text-base md:text-lg font-semibold text-center max-w-[90%] lg:max-w-[1000px]">
        Our consulting services are designed to help you optimize your business operations, leverage technology, and make data-driven decisions. We offer strategic insights and practical solutions to drive your business forward.
      </p>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-16 w-full max-w-7xl">
        {services.map((service, index) => (
          <ConsultantCard
            key={index}
            image={service.image}
            alt={service.alt}
            title={service.title}
            heading={service.heading}
            description={service.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Consultant;