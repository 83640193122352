import React, { useState } from 'react';


// Import images
import web_dev_img_2 from './Assets/Web design services/Focushub-UIUX-Designers.webp';
import web_des_img_3 from './Assets/Web design services/UXMastery-UIUX-designers.webp';
import fast_load_times from './Assets/Web design services/Fast-Load-Times-UIUX-designers.webp';
import brand_centric_design from './Assets/Web design services/Brand-Centric-design-UIUX-designers.webp';
import intuitive_user_experience from './Assets/Web design services/Intuitive-User-Experience-UIUX-designers.webp';
import interactive_elements from './Assets/Web design services/Interactive-Elements-UIUX-designers.webp';
import accessibility_and_inclusivity from './Assets/Web design services/Accessibility-and-Inclusivity-UIUX-designers.webp';
import consistent_branding from './Assets/Web design services/Consistent-Branding-UIUX-designers.webp';
import content_strategy_integration from './Assets/Web design services/Content-Strategy-Integration-UIUX-designers.webp';
import future_proof_designs from './Assets/Web design services/Future-Proof-Designs-UIUX-designers.webp';
import figma_logo from './Assets/Web design services/Figma-UIUX-designers.webp';
import penpot_logo from './Assets/Web design services/Penpot-UIUX-designers.webp';
import balsamiq_logo from './Assets/Web design services/Balsamiq-UIUX-designers.webp';
import canva_logo from './Assets/Web design services/Canva-UIUX-designers.webp';
import adobe_logo from './Assets/Web design services/AdobeXD-UIUX-designers.webp';
import ps_logo from './Assets/Web design services/Adobe-Photoshop-UIUX-designers.webp';
import shopify_logo from './Assets/Web design services/Shopify-UIUX-designers.webp';
import wordpress_logo from './Assets/Web design services/WordPress-UIUX-designers.webp';
import wix_logo from './Assets/Web design services/Wixstudio-UIUX-designers.webp';

const WebDesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
          Web Design Services
          </h1>
          <p className="text-lg max-w-xl mb-8">
            In today's digital world, first impressions matter. Our design specialists, including experienced UI/UX designers, create stunning, user-friendly websites that capture your brand's essence and engage your audience. We prioritize aesthetics, functionality, and user experience.
          </p>
          <button
            onClick={openModal}
            className="w-64 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Web Design
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_dev_img_2}
            alt="Web design"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_des_img_3}
            alt="Design process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            With PCL Infotech, your website will leave a lasting impression and provide a seamless experience across all devices, setting you apart from the competition.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Presence with PCL Infotech's Web Design
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            Whether you're launching a new site or revamping an existing one, our team works closely with you to understand your vision and translate it into an engaging online presence.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Web Design Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Fast Load Times",
              image: fast_load_times,
              description: `We design with performance in mind, optimizing images, code, and other elements to ensure your website loads quickly. Quick loading speeds enhance user satisfaction and can boost your search engine rankings.`
            },
            {
              title: "Brand-Centric Design",
              image: brand_centric_design,
              description: `We focus on creating designs that are true to your brand identity. Each aspect, from the color palette to the typography, is thoughtfully selected to ensure your website conveys your brand's message clearly and connects with your ideal audience.`
            },
            {
              title: "Intuitive User Experience",
              image: intuitive_user_experience,
              description: `Our designs are not only beautiful but also intuitive. Leveraging our UX application expertise, we craft intuitive user experiences that help visitors navigate smoothly, direct them to essential information and calls to action, and boost conversion rates.`
            },
            {
              title: "Interactive Elements",
              image: interactive_elements,
              description: `To enhance user engagement, we incorporate interactive elements such as animations, hover effects, and dynamic content. These elements captivate visitors, motivating them to discover additional content on your website.`
            },
            {
              title: "Accessibility and Inclusivity",
              image: accessibility_and_inclusivity,
              description: `We design websites that are accessible to everyone, including users with disabilities. Following web accessibility guidelines, we create an inclusive site that delivers a positive experience for every visitor.`
            },
            {
              title: "Consistent Branding",
              image: consistent_branding,
              description: `: Your website plays a crucial role in establishing your brand’s identity online. We maintain uniform branding across every page, crafting a unified and polished look that reinforces your brand’s identity.`
            },
            {
              title: "Content Strategy Integration",
              image: content_strategy_integration,
              description: `A well-designed website goes hand-in-hand with compelling content. We work hand-in-hand with content strategists to ensure your website's design aligns with and amplifies your message, achieving a perfect synergy between visuals and text.`
            },
            {
              title: "Future-Proof Designs",
              image: future_proof_designs,
              description: `Our designs are not just about meeting current needs; they’re about anticipating future trends and technologies. We build dynamic websites designed to grow your business, effortlessly integrating new features and functionalities as needed.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Technology Stack We Consider for Web Design
          </h2>
          <p className="text-lg mb-12">
            Our team of certified web designers is competent to work on different tools and platforms that leverage our clients with the flexibility to opt from choices.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-3 gap-8 items-center justify-items-center">
            {[
              figma_logo,
              penpot_logo,
              balsamiq_logo,
              canva_logo,
              adobe_logo,
              ps_logo,
              shopify_logo,
              wordpress_logo,
              wix_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebDesign;