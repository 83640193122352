import React, { useState } from 'react';
import './CSS/SearchEngineOptimization.css';
import dtg_seo_img_2 from './Assets/dtg_seo_img_2.png';
import dtg_seo_img_3 from './Assets/dtg_seo_img_3.png';
import dtg_sem_img_4 from './Assets/dtg_sem_img_4.png';
import comprehensive_keyword_research from './Assets/comprehensive_keyword_research.png';
import targeted_advertising_1 from './Assets/targeted_advertising_1.png';
import technical_seo from './Assets/technical_seo.png';
import content_strategy_and_creation from './Assets/content_strategy_and_creation.png';
import link_building from './Assets/link_building.png';
import local from './Assets/local.png';
import dtg_seo_img_5 from './Assets/dtg_seo_img_5.png';
import dtg_seo_img_6 from './Assets/dtg_seo_img_6.png';
import profile_optimization from './Assets/profile_optimization.png';
import review_management from './Assets/review_management.png';
import local_seo_strategies from './Assets/local_seo_strategies.png';
import enhanced_local_visibility from './Assets/enhanced_local_visibility.png';
import performance_tracking from './Assets/performance_tracking.png';
import geo_targeted_advertising from './Assets/geo_targeted_advertising.png';
import gsc_logo from './Assets/gsc_logo.png';
import google_analytics_logo_ from './Assets/google_analytics_logo_.png';
import semrusn from './Assets/semrusn.png';
import google_trend_logo from './Assets/google_trend_logo.png';
import screming__logo from './Assets/screming__logo.png';
import quake_logo from './Assets/quake_logo.png';
import google_my_business_logo from './Assets/google_my_business_logo.png';
import Modal from 'react-modal';


const SearchEngineOptimization = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full min-h-screen bg-white">
      {/* Main Title Section */}
      <div className="flex flex-col items-center justify-center text-center mt-8 px-4">
        <h2 className="text-3xl font-bold mb-4">Search Engine Optimization (SEO)</h2>
        <p className="max-w-4xl text-lg mb-8">
          In today's competitive digital landscape, climbing the search engine rankings is crucial to increasing your online visibility and attracting potential customers. At PCL Infotech, our Search Engine Optimization (SEO) services are meticulously designed to help your business rank higher for the keywords that matter most.
        </p>
      </div>

      {/* SEO Services Section */}
      <div className="flex flex-col md:flex-row items-center justify-center gap-2 mb-38 px-4">
        <img src={dtg_seo_img_2} alt="SEO Service 1" className="w-full md:w-1/3 h-auto" />
        <p className="text-lg text-center md:text-left max-w-2xl">
          We take a comprehensive approach to SEO, combining in-depth keyword research, content optimization, and technical SEO best practices to drive organic traffic and enhance your search engine performance.
        </p>
        <img src={dtg_seo_img_3} alt="SEO Service 2" className="w-full md:w-1/3 h-auto" />
      </div>

      {/* CTA Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-white text-black px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          Build my Search Engine Optimization (SEO)
        </button>
      </div>

      {/* Transform Section */}
      <div className="text-center px-4 mt-16">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          Transform Your Online Business with PCL Infotech's Search Engine Optimization (SEO)
        </h2>
        <p className="max-w-4xl mx-auto text-lg">
          At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth. Let us be your partner in creating a powerful, user-friendly online store that converts visitors into loyal customers and drives sustained business success.
        </p>
      </div>

      {/* Why Choose Us Section */}
      <div className="text-center mt-16">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 px-4">
          <img src={dtg_sem_img_4} alt="Why Choose Us" className="w-64 h-auto" />
          <h2 className="text-2xl font-bold">Why Choose PCL Infotech for Your Search Engine Optimization (SEO) Needs</h2>
        </div>
      </div>

      {/* Service Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mt-16 max-w-7xl mx-auto">
        {[
          {
            image: comprehensive_keyword_research,
            title: "Comprehensive Keyword Research",
            description: "We start by identifying the keywords and phrases that your target audience is searching for. By understanding what drives traffic in your industry, we can optimize your content to attract high-intent visitors who are more likely to convert."
          },
          {
            image: targeted_advertising_1,
            title: "Targeted Advertising",
            description: "Our team enhances your website's content, meta tags, headings, and internal linking structure to ensure that it is fully optimized for both search engines and users."
          },
          {
            image: technical_seo,
            title: "Technical SEO",
            description: "We address the technical aspects of SEO that can significantly impact your rankings and improve site speed, mobile-friendliness, and indexing."
          },
          {
            image: content_strategy_and_creation,
            title: "Content Strategy and Creation",
            description: "High-quality content is at the heart of effective SEO. We develop a content strategy that aligns with your business goals and engages your audience."
          },
          {
            image: link_building,
            title: "Link Building",
            description: "Building high-quality backlinks is essential. We develop a strategic link-building plan that includes earning links from reputable websites."
          },
          {
            image: local,
            title: "Local SEO",
            description: "Our local SEO services will help you dominate local search results. We optimize your Google My Business and manage local citations."
          }
        ].map((service, index) => (
          <div key={index} className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <img src={service.image} alt={service.title} className="w-24 h-24 mb-6" />
            <h3 className="text-xl font-bold mb-4">{service.title}</h3>
            <p className="text-center">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Contact Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          CONTACT US
        </button>
      </div>

      {/* Local SEO Section */}
      <div className="mt-24 px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Google My Business - Local SEO</h2>
          <p className="max-w-4xl mx-auto text-lg mb-8">
            For businesses that thrive on local customers, visibility in local search results is crucial. At PCL Infotech, our Google My Business optimization services are designed to put your business on the map—literally.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center p-6 gap-4">
          <img src={dtg_seo_img_5} alt="Local SEO 1" className="w-full md:w-1/3 h-auto" />
          <p className="text-xl text-center font-semibold max-w-2xl">
            Our team of experts meticulously manages your Google My Business profile, optimizing every aspect from accurate business information to engaging photos and customer reviews.
          </p>
          <img src={dtg_seo_img_6} alt="Local SEO 2" className="w-full md:w-1/3 h-auto" />
        </div>
      </div>

      {/* Local SEO Services Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mt-16 max-w-7xl mx-auto">
        {[
          {
            image: profile_optimization,
            title: "Profile Optimization",
            description: "We optimize your Google My Business profile to reflect your business accurately, keeping all information up-to-date and consistent."
          },
          {
            image: review_management,
            title: "Review Management",
            description: "We help you manage and respond to reviews effectively, enhancing your online reputation and local search rankings."
          },
          {
            image: local_seo_strategies,
            title: "Local SEO Strategies",
            description: "We implement targeted local SEO strategies that boost your visibility in local searches."
          },
          {
            image: enhanced_local_visibility,
            title: "Enhanced Local Visibility",
            description: "We leverage Google My Business features to enhance your profile's engagement and visibility."
          },
          {
            image: performance_tracking,
            title: "Performance Tracking",
            description: "We provide ongoing monitoring and reporting to track your Google My Business performance."
          },
          {
            image: geo_targeted_advertising,
            title: "Geo-Targeted Advertising",
            description: "We can integrate geo-targeted advertising campaigns to reach customers in your vicinity."
          }
        ].map((service, index) => (
          <div key={index} className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <img src={service.image} alt={service.title} className="w-24 h-24 mb-6" />
            <h3 className="text-xl font-bold mb-4">{service.title}</h3>
            <p className="text-center">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Tools We Use for Search Engine Optimization
          </h2>
          <p className="text-lg mb-12">
            We leverage industry-leading tools and platforms to deliver optimal SEO results for our clients.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-3 gap-8 items-center justify-items-center">
            <img src={gsc_logo} alt="Google Search Console" className="w-20 md:w-32 h-auto" />
            <img src={google_analytics_logo_} alt="Google Analytics" className="w-20 md:w-32 h-auto" />
            <img src={semrusn} alt="SEMrush" className="w-20 md:w-32 h-auto" />
            <img src={google_trend_logo} alt="Google Trends" className="w-20 md:w-32 h-auto" />
            <img src={screming__logo} alt="Screaming Frog" className="w-20 md:w-32 h-auto" />
            <img src={quake_logo} alt="Quake" className="w-20 md:w-32 h-auto" />
            <img src={google_my_business_logo} alt="Google My Business" className="w-20 md:w-32 h-auto" />
          </div>
        </div>
      </div>

      {/* Modal Form */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content">
        <div className="p-5">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col">
              <label className="font-semibold">First Name</label>
              <input
                className="border rounded-md p-2"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <label className="font-semibold">Last Name</label>
              <input
                className="border rounded-md p-2"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="font-semibold">Email</label>
              <input
                className="border rounded-md p-2"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label className="font-semibold">Phone Number</label>
              <input
                className="border rounded-md p-2"
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-semibold">Message</label>
              <textarea
                className="border rounded-md p-2"
                name="message"
                placeholder="Write your message.."
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="bg-blue-600 text-white rounded-md p-2">Send Message</button>
            {isSubmitted && <p className="text-green-500 mt-2">Thank you! Your form has been submitted.</p>}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SearchEngineOptimization;