import React, { useState } from 'react';
import Modal from 'react-modal';

import hs_wp_img_2 from './Assets/Wordpress Hosting/WordPress-Hosting.webp'
import optimized_speed from './Assets/Wordpress Hosting/Optimized-For-Speed-WordPress-Hosting.webp'
import top_tier_security from './Assets/Wordpress Hosting/Top-Tier-Security-WordPress-Hosting.webp'
import reliable_uptime from './Assets/Wordpress Hosting/Reliable-Uptime-WordPress-Hosting.webp'
import automatic_backups_updates from './Assets/Wordpress Hosting/Automatic-Backups-And-Updates-WordPress-Hosting.webp'
import seamless_scalability from './Assets/Wordpress Hosting/Seamless-Scalability-WordPress-Hosting.webp'
import wordPress_installation from './Assets/Wordpress Hosting/One-Click-WordPress-Installation-WordPress-Hosting.webp'


const WordpressHosting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row justify-center items-center gap-20 bg-blue-600 px-6 py-8 lg:py-12">
        <div className="flex flex-col gap-10 text-center lg:text-left">
          <h1 className="text-3xl lg:text-4xl text-white font-bold">WordPress Hosting</h1>
          <p className="text-lg text-white max-w-2xl">
            WordPress powers over 40% of the web, and having a reliable hosting solution tailored to its unique demands is essential for optimal performance. At PCL Infotech, we specialize in WordPress hosting that ensures your website is fast, secure, and always online.
          </p>
          <button
            onClick={() => window.open("http://host.pclinfotech.com/", "_blank")}
            className="px-6 py-2 bg-white text-black font-semibold rounded-md shadow-lg hover:bg-gray-100 transition-colors"
          >
            Click here
          </button>
        </div>
        <div className="w-full max-w-xl lg:w-auto">
          <img
            src={hs_wp_img_2}
            alt="Team collaborating on WordPress hosting solutions"
            className="w-full h-auto"
          />
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <h2 className="text-2xl lg:text-3xl text-center font-bold mb-12">
          Our WordPress Hosting Services Include
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {[
            {
              src: [optimized_speed],
              alt: "Three laptops with a cloud above them, symbolizing WordPress hosting and cloud-based technology solutions.",
              title: "Optimized for Speed",
              description: "Our WordPress hosting is specifically designed to deliver fast load times, ensuring a seamless user experience and reducing bounce rates."
            },
            {
              src: [top_tier_security],
              alt: "A man in a blue shirt focused on his laptop, representing efficient WordPress hosting solutions.",
              title: "Top-Tier Security",
              description: "WordPress is a popular target for hackers, but our hosting services include built-in security features such as firewalls and malware scanning."
            },
            {
              src: [reliable_uptime],
              alt: "Two business professionals shaking hands, symbolizing agreement, with a check mark, representing successful WordPress hosting.",
              title: "Reliable Uptime",
              description: "Downtime can damage your reputation and hurt your SEO rankings. We ensure maximum uptime through robust infrastructure."
            },
            {
              src: [automatic_backups_updates],
              alt: "Cloud computing services in India, showcasing WordPress hosting solutions for enhanced website performance and reliability.",
              title: "Automatic Backups and Updates",
              description: "Never worry about losing your data. We provide regular backups and automatic WordPress updates to keep your site secure."
            },
            {
              src: [seamless_scalability],
              alt: "A man stands before a laptop, wearing headphones, engaged with the screen, focused on WordPress hosting tasks.",
              title: "Seamless Scalability",
              description: "Whether you're starting with a small blog or running a large e-commerce platform, our hosting is built to scale with your growth."
            },
            {
              src: [wordPress_installation],
              alt: "Two individuals collaborating on a website project using a computer, focused on WordPress hosting solutions.",
              title: "One-Click WordPress Installation",
              description: "Get your WordPress site up and running quickly with our simple, one-click installation process."
            }
          ].map((service, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow bg-white"
            >
              <img
                src={service.src}
                alt={service.alt}
                className="w-20 h-20 mb-6"
              />
              <h3 className="text-xl font-semibold mb-4 text-center">{service.title}</h3>
              <p className="text-center text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-blue-700 text-white rounded-lg shadow-lg hover:bg-blue-800 transition-colors"
          >
            CONTACT US
          </button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal-content"
      >
        <div className="bg-white p-8 rounded-lg max-w-2xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium mb-2">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-2 border rounded h-32"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Send Message
            </button>
            {isSubmitted && (
              <p className="text-green-600 text-center">Thank you! Your form has been submitted.</p>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default WordpressHosting;