import React from 'react';
import DataAnalysis from './Assets/R&D/Data-Analysis-research-and-development.webp';
import Trend from './Assets/R&D/Trend-Identification-research-and-development.webp';
import strategy from './Assets/R&D/Strategy-Development-research-and-development.webp';
import action from './Assets/R&D/Actionable-Insights-research-and-development.webp';
import continuous from './Assets/R&D/AI-Model-Development-research-and-development.webp';
import aimodel from './Assets/R&D/coding-1853305_1280 2_5_11zon.webp';
import advancedAnalytics from './Assets/R&D/Advanced-Analytics-research-and-development.webp';
import innovation from './Assets/R&D/Innovation-Implementation-research-and-development.webp';
import intelligent from './Assets/R&D/Intelligent-Automation-research-and-development.webp';
import empower from './Assets/R&D/Empowering-Teams-research-and-development.webp';
import AiResearch from './Assets/R&D/AI-Research-Insights-research-and-development.webp';


const RDStrategySection = () => {
    return (
        <div className="w-full bg-white">
            {/* Hero Section */}
            <div className="relative bg-blue-500 text-white p-8 md:p-12">
                <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between gap-8">
                    <div className="md:w-2/3">
                        <h1 className="text-4xl text-white md:text-5xl font-bold mb-6">
                            Research & Development
                        </h1>
                        <p className="text-lg leading-relaxed">
                            In R&D (Research and Development), clear plans guide business goals and use data to drive decisions. This strategy enables companies to grasp market dynamics and maintain a competitive edge. Through data analysis, businesses can make well-informed decisions that drive growth and foster innovation.
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full min-h-screen bg-white p-4 md:p-8">
                <h1 className="text-4xl md:text-5xl text-blue-600 font-bold text-center mb-16">
                    Strategy & Analytics
                </h1>

                {/* Data Analysis Section */}
                <div className="max-w-7xl mx-auto">
                    <h2 className="text-3xl text-blue-600 font-bold mb-4">Data Analysis:</h2>
                    <p className="text-gray-600 mb-8">
                        Our data analysis solutions offer valuable insights into your data, empowering you to make well-informed decisions.
                    </p>

                    <div className="flex flex-col md:flex-row gap-8 items-start">
                        <div className="w-full md:w-1/2">
                            <img
                                src={DataAnalysis}
                                alt="Data Analysis Dashboard"
                                className="w-full h-auto rounded-lg shadow-lg"
                            />
                        </div>

                        <div className="w-full md:w-1/2 space-y-8">
                            <div>
                                <h3 className="text-xl font-bold mb-2">• Data Collection & Integration</h3>
                                <p className="text-gray-600">
                                    We help gather and integrate data from various sources, providing a comprehensive view of your business. Our commitment to Research and Development ensures innovative data solutions.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-2">• Advanced Analytics Techniques</h3>
                                <p className="text-gray-600">
                                    We leverage cutting-edge techniques such as predictive analytics and machine learning to reveal valuable insights. Continuous Research and Development keeps us ahead in data analytics.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-2">• Customized Reporting</h3>
                                <p className="text-gray-600">
                                    We create customized reports and dashboards for clear, actionable insights. Our ongoing Research and Development in reporting technologies ensure ease of interpretation and action.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Trend Identification Section */}
                <div className="w-full bg-white p md:p-8 lg:p-12">
                    <div className="max-w-8xl mx-auto">
                        <h2 className="text-3xl md:text-4xl text-blue-600 font-bold mb-6">Trend Identification:</h2>
                        <p className="text-gray-600 mb-8 max-w-4xl">
                            Staying competitive requires a deep understanding of market dynamics and industry developments. Our trend identification services include
                        </p>

                        <div className="flex flex-col-reverse md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Market Research</h3>
                                    <p className="text-gray-600">
                                        We conduct thorough market research to identify emerging trends, customer behaviors, and competitive dynamics.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Industry Benchmarking</h3>
                                    <p className="text-gray-600">
                                        We assess your performance to industry standards to identify your strengths and potential areas for growth.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Competitive Analysis</h3>
                                    <p className="text-gray-600">
                                        We design tailored reports and dashboards that display data in a straightforward, actionable way, simplifying interpretation and decision-making.
                                    </p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2">
                                <img
                                    src={Trend}
                                    alt="Professional analyzing trends on laptop"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full bg-white p-4 md:p-8 lg:p-12">
                <div className="max-w-7xl mx-auto space-y-16">
                    <h2 className="text-3xl md:text-4xl text-blue-600 font-bold mb-6">Strategy Development:</h2>
                    <p className="text-gray-600 mb-8">
                        Developing data-driven strategies is key to aligning your business goals with actionable plans. Our strategy development process involves
                    </p>

                    <div className="flex flex-col md:flex-row gap-8 items-start">
                        <div className="w-full md:w-1/2">
                            <img
                                src={strategy}
                                alt="Strategy Development"
                                className="w-full h-auto rounded-lg shadow-lg"
                            />
                        </div>

                        <div className="w-full md:w-1/2 space-y-8">
                            <div>
                                <h3 className="text-xl font-bold mb-2">• Strategic Planning Workshops</h3>
                                <p className="text-gray-600">
                                    We facilitate workshops to align your team on strategic objectives and priorities based on data insights.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-2">• Scenario Planning</h3>
                                <p className="text-gray-600">
                                    We leverage data to simulate various business scenarios, enabling you to assess possible results and make well-informed decisions.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-2">• Goal Setting</h3>
                                <p className="text-gray-600">
                                    We work with you to set clear, measurable goals that are aligned with your strategic vision and supported by data insights.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full bg-white p-4 md:p-8 lg:p-12">
                <div className="max-w-7xl mx-auto space-y-16">
                    {/* Actionable Insights Section */}
                    <section>
                        <h2 className="text-3xl md:text-4xl text-blue-600 font-bold mb-4">Actionable Insights</h2>
                        <p className="text-gray-600 mb-8">
                            Our primary goal is to convert data into valuable insights that drive action.
                        </p>

                        <div className="flex flex-col md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Insight Generation</h3>
                                    <p className="text-gray-600">
                                        We analyze data to generate insights that drive business decisions, from operational improvements to strategic investments, including Research and Development.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Decision Support</h3>
                                    <p className="text-gray-600">
                                        Our insights provide support for key decisions, such as market entry, product development, and customer acquisition, with a focus on Research and Development.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Performance Monitoring</h3>
                                    <p className="text-gray-600">
                                        We help you set up systems to continuously monitor performance metrics, ensuring you can adapt strategies in response to changing conditions.
                                    </p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2">
                                <img
                                    src={action}
                                    alt="Light bulb concept"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>
                        </div>
                    </section>

                    {/* Continuous Improvement Section */}
                    <section>
                        <h2 className="text-3xl md:text-4xl text-blue-600 font-bold mb-4">Continuous Improvement:</h2>
                        <p className="text-gray-600 mb-8">
                            Our services don't stop at providing insights. We also focus on
                        </p>

                        <div className="flex flex-col-reverse md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2">
                                <img
                                    src={continuous}
                                    alt="Feedback system illustration"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>

                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Feedback Loops</h3>
                                    <p className="text-gray-600">
                                        We implement systems for gathering feedback to enhance strategies and strengthen decision-making processes as they evolve.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Ongoing Support</h3>
                                    <p className="text-gray-600">
                                        We provide continuous support and guidance to help you refine strategies as fresh data and trends develop.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">• Training & Empowerment</h3>
                                    <p className="text-gray-600">
                                        We offer training to your team on data analysis tools and methods, equipping them with the skills to utilize data efficiently.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="w-full bg-white p-4 md:p-8 lg:p-12">
                <div className="max-w-7xl mx-auto space-y-16">
                    <section>
                        <h1 className="text-4xl md:text-5xl text-blue-600 font-bold text-center mb-6">AI Research & Development</h1>
                        <p className="text-gray-600 text-center mb-12 max-w-4xl mx-auto">
                            AI Research & Development helps businesses stay competitive in today's fast-changing world. At R&D labs/tech, we focus on creating smart AI solutions that solve real problems. From automation to advanced analytics, we build tools that drive growth. Join us in pioneering innovation and shaping the future with AI.
                        </p>
                    </section>

                    {/* AI Model Development Section */}
                    <section>
                        <h2 className="text-3xl text-blue-600 font-bold mb-4">AI Model Development</h2>
                        <p className="text-gray-600 mb-8">
                            Creating novel and efficient AI models tailored to solve specific challenges through continuous R&D.
                        </p>

                        <div className="flex flex-col md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2">
                                <img
                                    src={aimodel}
                                    alt="AI code on laptop screen"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>

                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Custom AI Solutions</h3>
                                    <p className="text-gray-600">
                                        We design AI systems tailored to your business needs, addressing unique challenges with precision through innovative R&D efforts.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Predictive Modeling</h3>
                                    <p className="text-gray-600">
                                        Leveraging our robust data, we develop models that forecast trends, enabling intelligent decision-making supported by advanced R&D techniques.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Optimization Algorithms</h3>
                                    <p className="text-gray-600">
                                        Enhancing operational efficiency through AI-powered optimization techniques that reduce costs and increase productivity, driven by our ongoing R&D initiatives.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Advanced Analytics Section */}
                    <section>
                        <h2 className="text-3xl text-blue-600 font-bold mb-4">Advanced Analytics</h2>
                        <p className="text-gray-600 mb-8">
                            Extracting actionable insights from raw data with cutting-edge AI and ML techniques.
                        </p>

                        <div className="flex flex-col-reverse md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Data Integration & Analysis</h3>
                                    <p className="text-gray-600">
                                        We combine data from multiple sources, offering a unified view to achieve meaningful patterns.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Trend Analysis</h3>
                                    <p className="text-gray-600">
                                        Identifying market trends and customer behaviors to position your business strategically.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Real-Time Decision Support</h3>
                                    <p className="text-gray-600">
                                        Deploying AI-driven analytics for instant, data-backed decision making.
                                    </p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2">
                                <img
                                    src={advancedAnalytics}
                                    alt="Analytics dashboard on laptop"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>
                        </div>
                    </section>

                    {/* Innovation Implementation Section */}
                    <section>
                        <h2 className="text-3xl text-blue-600 font-bold mb-4">Innovation Implementation</h2>
                        <p className="text-gray-600 mb-8">
                            Turning R&D into practical solutions that drive measurable results.
                        </p>

                        <div className="flex flex-col md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2">
                                <img
                                    src={innovation}
                                    alt="Innovation process illustration"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>

                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Prototyping & Testing</h3>
                                    <p className="text-gray-600">
                                        Developing and testing AI prototypes to ensure performance, scalability, and reliability.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Deployment Support</h3>
                                    <p className="text-gray-600">
                                        Helping integrate AI solutions into your existing infrastructure for seamless implementation.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Performance Monitoring</h3>
                                    <p className="text-gray-600">
                                        Setting up systems to evaluate the success of AI applications and adapt as needed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="w-full bg-white p-4 md:p-8 lg:p-12">
                <div className="max-w-7xl mx-auto space-y-16">
                    {/* Intelligent Automation Section */}
                    <section>
                        <h2 className="text-3xl text-blue-600 font-bold mb-4">Intelligent Automation</h2>
                        <p className="text-gray-600 mb-8">Automating processes to enhance efficiency and reduce manual effort.</p>

                        <div className="flex flex-col md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• R&D in Process Automation</h3>
                                    <p className="text-gray-600">We build AI solutions to automate repetitive tasks, enabling your team to focus on strategic priorities.</p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Natural Language Processing (NLP)</h3>
                                    <p className="text-gray-600">Enabling seamless interaction between humans and machines through text and speech recognition.</p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Computer Vision</h3>
                                    <p className="text-gray-600">Deploying advanced image and video analysis solutions to revolutionize sectors like security, healthcare, and retail.</p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2">
                                <img
                                    src={intelligent}
                                    alt="Process automation equipment"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>
                        </div>
                    </section>

                    {/* Empowering Teams Section */}
                    <section>
                        <h2 className="text-3xl text-blue-600 font-bold mb-4">Empowering Teams</h2>
                        <p className="text-gray-600 mb-8">Building AI literacy within your organization.</p>

                        <div className="flex flex-col-reverse md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2">
                                <img
                                    src={empower}
                                    alt="Team collaboration"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>

                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Workshops & Training</h3>
                                    <p className="text-gray-600">We provide training sessions to empower your team with the skills needed to harness AI effectively.</p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Ongoing Support</h3>
                                    <p className="text-gray-600">Offering continuous consultation and updates to keep your AI solutions aligned with evolving goals.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* AI Research Insights Section */}
                    <section>
                        <h2 className="text-3xl text-blue-600 font-bold mb-4">AI Research Insights</h2>
                        <p className="text-gray-600 mb-8">Leading the way in AI advancement through comprehensive research and profound insights.</p>

                        <div className="flex flex-col md:flex-row gap-8 items-start">
                            <div className="w-full md:w-1/2 space-y-8">
                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Emerging Technology Research</h3>
                                    <p className="text-gray-600">We explore new advancements in AI, ensuring you remain competitive in a rapidly evolving market.</p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• AI Ethics and Governance</h3>
                                    <p className="text-gray-600">Incorporating responsible AI practices to maintain transparency and build trust in AI applications.</p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-bold mb-3">• Academic Collaboration</h3>
                                    <p className="text-gray-600">Partnering with universities and research institutions to harness the latest developments in AI.</p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/2">
                                <img
                                    src={AiResearch}
                                    alt="AI automation interface"
                                    className="w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default RDStrategySection;
