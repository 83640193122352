import React, { useState, useEffect } from 'react';
import { Phone, MessageSquare } from 'lucide-react';
import hero_down_img from '../Assets/hero_down_img.webp'
import hero_img_3 from '../Assets/hero_img_3.webp';
import hero_img_2 from '../Assets/hero_img_2.webp';
import hero_img_1 from '../Assets/hero_img_1.webp';
import hero_img_4 from '../Assets/hero_img_4.webp';

function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const slides = [
    {
      title: "Software Development",
      description: "Revolutionize your business with custom software solutions designed to meet your unique requirements.",
      image: [hero_img_3],
      link: "/services"
    },
    {
      title: "Cloud Solutions",
      description: "Empower your business with flexible and secure cloud infrastructure for seamless scalability and innovation.",
      image: [hero_img_2],
      link: "/cloud"
    },
    {
      title: "Training and Placement",
      description: "Advance your career with industry-leading training and placement programs that set you up for success.",
      image: [hero_img_1],
      link: "/web-development"
    },
    {
      title: "Communication",
      description: "Enhance your business communication with modern, efficient, and scalable solutions tailored to your needs.",
      image: [hero_img_4],
      link: "/communication"
    }
  ];

  // Auto-slide functionality
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsModalOpen(false);
    setTimeout(() => setIsSubmitted(false), 5000);
  };

  return (
    <div className="relative min-h-screen bg-gray-50">
      {/* Hero Slider */}
      <div className="relative h-[80vh] md:h-[70vh] lg:h-[calc(100vh-4rem)] min-h-[400px] md:min-h-[500px] overflow-hidden">
        <div className="absolute inset-0 z-10" />

        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 w-full h-full transition-transform duration-700 ease-in-out ${currentSlide === index
              ? 'translate-x-0 opacity-100'
              : currentSlide < index
                ? 'translate-x-full opacity-0'
                : '-translate-x-full opacity-0'
              }`}
          >
            <div className="relative w-full h-full">
              <img
                src={slide.image}
                alt={slide.title}
                className="absolute inset-0 object-cover w-full h-full"
              />
              <div className="absolute inset-0 z-20 flex items-center">
                <div className="container px-4 mx-auto">
                  <div className="max-w-2xl space-y-6 md:space-y-8">
                    <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl md:text-5xl lg:text-6xl">
                      {slide.title}
                    </h2>
                    <p className="text-base text-white/90 sm:text-lg md:text-xl">
                      {slide.description}
                    </p>
                    <div className="flex flex-col gap-4 sm:flex-row">
                      <a
                        href={slide.link}
                        className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold text-gray-900 transition-colors bg-white rounded-lg shadow-lg hover:bg-gray-100 focus:ring-2 focus:ring-white/20 focus:outline-none"
                      >
                        Learn More
                      </a>
                      <button
                        onClick={() => setIsModalOpen(true)}
                        className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold text-white transition-colors border-2 border-white rounded-lg shadow-lg hover:bg-white hover:text-gray-900 focus:ring-2 focus:ring-white/20 focus:outline-none"
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* Navigation Dots */}
        <div className="absolute bottom-6 left-1/2 z-30 flex -translate-x-1/2 space-x-3">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-2.5 h-2.5 rounded-full transition-all duration-200 ${currentSlide === index ? 'bg-white scale-125' : 'bg-white/50 hover:bg-white/75'
                }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>

      {/* Floating Contact Buttons */}
      <div className="fixed z-50 flex flex-col gap-3 transition-transform right-4 bottom-4 sm:right-6 sm:bottom-6 md:right-8 md:bottom-8">
        <a
          href="https://wa.me/7358791015"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-12 h-12 text-white transition-all duration-200 bg-green-500 rounded-full shadow-lg hover:bg-green-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
        >
          <MessageSquare className="w-5 h-5" />
        </a>
        <a
          href="tel:+9173587-91015"
          className="flex items-center justify-center w-12 h-12 text-white transition-all duration-200 bg-blue-600 rounded-full shadow-lg hover:bg-blue-700 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
        >
          <Phone className="w-5 h-5" />
        </a>
      </div>

      {/* Expertise Section */}
      <div className="relative py-12 sm:py-16 md:py-20 lg:py-24">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-12">
            {/* Image Container */}
            <div className="w-full lg:w-1/2">
              <div className="relative aspect-[4/3] w-full max-w-2xl mx-auto lg:mx-0">
                <img
                  src={hero_down_img}
                  alt="Expertise IT Service"
                  className="object-cover w-full h-full rounded-lg shadow-xl"
                />
              </div>
            </div>

            {/* Content Container */}
            <div className="w-full lg:w-1/2 space-y-6">
              <div className="max-w-xl mx-auto lg:mx-0">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6">
                  <span className="text-blue-600">Our</span>{' '}
                  <span className="text-gray-900">Expertise</span>
                </h2>
                <div className="space-y-4">
                  <p className="text-gray-700 text-base sm:text-lg leading-relaxed">
                    Elevate your business with PCL Infotech's IT services and Custom Software Development Solutions. We integrate AI, Quantum Computing, AGI, and Blockchain to create innovative, business-driven solutions.
                  </p>
                  <p className="text-gray-700 text-base sm:text-lg leading-relaxed">
                    Focused on driving innovation and efficiency, our quality-driven development exceeds expectations. Stay ahead with cutting-edge technology. Empower your business to gain a competitive edge.
                  </p>
                  <p className="text-gray-700 text-base sm:text-lg leading-relaxed">
                    Let us help you navigate a rapidly evolving market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
          <div className="w-full max-w-md p-6 bg-white rounded-xl shadow-2xl">
            <h3 className="mb-6 text-2xl font-bold text-gray-900">Get in Touch</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid gap-4 sm:grid-cols-2">
                <div>
                  <label className="block mb-1.5 text-sm font-medium text-gray-700">First Name</label>
                  <input
                    type="text"
                    value={formData.firstName}
                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                    className="w-full px-3 py-2 text-gray-900 transition-colors border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-1.5 text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    type="text"
                    value={formData.lastName}
                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    className="w-full px-3 py-2 text-gray-900 transition-colors border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block mb-1.5 text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-3 py-2 text-gray-900 transition-colors border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block mb-1.5 text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  className="w-full px-3 py-2 text-gray-900 transition-colors border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block mb-1.5 text-sm font-medium text-gray-700">Message</label>
                <textarea
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  className="w-full h-32 px-3 py-2 text-gray-900 transition-colors border border-gray-300 rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 transition-colors bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Success Toast */}
      {isSubmitted && (
        <div className="fixed z-50 flex items-center px-4 py-3 text-white bg-green-500 rounded-lg shadow-lg bottom-4 right-4 sm:right-6">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Thank you! Your message has been sent.
        </div>
      )}
    </div>
  );
}

export default App;