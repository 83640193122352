import React, { useState } from 'react';
import hs_vps_img_2 from './Assets/VPS Hosting/VPS-Hosting.webp'
import dedicated_resources from './Assets/VPS Hosting/Dedicated-Resources-VPS-Hosting.webp'
import scalability from './Assets/VPS Hosting/Scalability-VPS-Hosting.webp'
import full_control from './Assets/VPS Hosting/Full-Control-VPS-Hosting.webp'
import enhanced_security from './Assets/VPS Hosting/Enhanced-Security-VPS-Hosting.webp'
import optimal_performance from './Assets/VPS Hosting/Optimal-Performance-VPS-Hosting.webp'
import custom_configuration from './Assets/VPS Hosting/Custom-Configuration-VPS-Hosting.webp'
import flexible_operating_systems from './Assets/VPS Hosting/Flexible-Operating-Systems-VPS-Hosting.webp'
import managed_unmanaged from './Assets/VPS Hosting/Managed-Or-Unmanaged-VPS-Hosting.webp'
import support from './Assets/VPS Hosting/247support-VPS-Hosting.webp'



const VPSHosting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      setIsModalOpen(false);
      setTimeout(() => setIsSubmitted(false), 5000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const features = [
    {
      img: dedicated_resources,
      alt: "A woman using a laptop, focused on a login page for VPS Hosting services",
      title: "Dedicated Resources",
      description: "Unlike shared hosting, VPS hosting provides you with your own allocated resources such as CPU, RAM, and storage. This ensures that your website's performance is not affected by other users."
    },
    {
      img: scalability,
      alt: "A person working at a desk with a computer and laptop, focused on VPS hosting tasks",
      title: "Scalability",
      description: "As your business grows, your hosting requirements may change. Our VPS hosting solutions are designed to scale with your needs, providing more resources whenever you need them without any downtime or disruptions."
    },
    {
      img: full_control,
      alt: "A woman reviews a job application form on her phone, considering opportunities in VPS Hosting",
      title: "Full Control",
      description: "With root access, you gain complete control over your server, allowing you to install custom software, configure your server environment, and manage all aspects of your hosting setup."
    },
    {
      img: enhanced_security,
      alt: "Visual guide on safeguarding data from cyber attacks, emphasizing the benefits of VPS hosting for enhanced security",
      title: "Enhanced Security",
      description: "VPS hosting offers a more secure environment compared to shared hosting, as your virtual server is isolated from other users. We also implement advanced security measures such as firewalls, DDoS protection, and regular monitoring."
    },
    {
      img: optimal_performance,
      alt: "Man checking data on his phone, illustrating the convenience of VPS hosting for mobile access",
      title: "Optimal Performance",
      description: "Our VPS servers are optimized for speed and reliability, ensuring that your high-traffic website or resource-intensive application runs smoothly, even during peak times."
    },
    {
      img: custom_configuration,
      alt: "A woman engages with a chatbot to assist a customer regarding VPS Hosting services",
      title: "Custom Configuration",
      description: "Our VPS servers are optimized for speed and reliability, ensuring that your high-traffic website or resource-intensive application runs smoothly, even during peak times."
    },
    {
      img: flexible_operating_systems,
      alt: "A man stands beside a laptop and phone, representing the concept of VPS hosting and modern technology",
      title: "Flexible Operating Systems",
      description: "Choose from a range of operating systems (Linux, Windows) that best suit your needs."
    },
    {
      img: managed_unmanaged,
      alt: "A woman on a window ledge using a laptop and phone, symbolizing remote work and VPS hosting flexibility",
      title: "Managed or Unmanaged VPS",
      description: "Whether you need a fully managed solution or prefer to handle server administration yourself, we offer both managed and unmanaged VPS hosting options."
    },
    {
      img: support,
      alt: "A woman engages in conversation with a child while seated in a chair, fostering a warm and nurturing environment",
      title: "24/7 Support",
      description: "Need subdomains for different areas of your business? We assist in setting up and managing subdomains to give your website structure and flexibility."
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-8 md:gap-20 bg-blue-600 p-8">
        <div className="text-white max-w-2xl">
          <h1 className="text-4xl text-white font-bold mb-6">VPS Hosting</h1>
          <p className="text-lg mb-8">
            For businesses that demand more power, flexibility, and control over their hosting environment, Virtual Private Server (VPS) hosting is the perfect solution. At PCL Infotech, our VPS hosting services offer a scalable, secure, and highly customizable hosting environment tailored to meet your unique needs.
          </p>
          <button
            onClick={() => window.open("http://host.pclinfotech.com/", "_blank")}
            className="px-6 py-2 bg-white text-black font-semibold rounded-md shadow-lg hover:bg-gray-100 transition-colors"
          >
            Click here
          </button>
        </div>
        <div className="w-full md:w-1/2 max-w-xl">
          <img
            src={hs_vps_img_2}
            alt="Isometric illustration of a businessman beside a laptop and cloud, symbolizing VPS hosting solutions"
            className="w-full h-auto"
          />
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center text-blue-800 mb-12">
          Our VPS Hosting Services Include
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-6 rounded-3xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <img
                src={feature.img}
                alt={feature.alt}
                className="w-24 h-24 object-contain mb-6"
              />
              <h3 className="text-xl font-semibold text-center mb-4">
                {feature.title}
              </h3>
              <p className="text-center text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
        <div className="text-center mt-12">
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-8 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-700 transition-colors"
          >
            CONTACT US
          </button>
        </div>
      </div>

    </div>
  );
};

export default VPSHosting;