import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Pages/Home';
import WebDevelopment from './Pages/WebDevelopment';
import MobileAppDevelopment from './Pages/MobileAppDevelopment';
import Webdesign from './Pages/Webdesign';
import SoftwareProducts from './Pages/SoftwareProducts';
import ECommerceWebsiteDevelopment from './Pages/ECommerceWebsiteDevelopment';
import EmailTemplates from './Pages/EmailTemplates';
import CMSWebsiteDevelopment from './Pages/CMSWebsiteDevelopment';
import OpenSourceDevelopment from './Pages/OpenSourceDevelopment';
import PaymentGatewayIntegration from './Pages/PaymentGatewayIntegration';
import ContentWriting from './Pages/ContentWriting';
import SearchEngineMarketing from './Pages/SearchEngineMarketing';
import SocialMediaMarketing from './Pages/SocialMediaMarketing';
import SearchEngineOptimization from './Pages/SearchEngineOptimization';
import Domain from './Pages/Domain';
import VPSHosting from './Pages/VPSHosting';
import WordpressHosting from './Pages/WordpressHosting';
import CloudServer from './Pages/CloudServer';
import SoftwareTrainings from './Pages/SoftwareTrainings';
import DataAnalytics from './Pages/DataAnalytics'
import ProductsDesign from './Pages/ProductsDesign';
import JobPortal from './Pages/JobPortal';
import Internship from './Pages/Internship';
import Branding from './Pages/Branding';
import ContactInfo from './Pages/ContactInfo';
import LoginSignup from './Pages/LoginSignup';
import TermsConditions from './Pages/TermsConditions';
import Privacypolicy from './Pages/Privacypolicy';
import RD from './Pages/R&D';
import Footer from './Components/Footer/Footer';



function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/web-development' element={<WebDevelopment />} />
          <Route path='/mobile-app-development' element={<MobileAppDevelopment />} />
          <Route path='/web-design' element={<Webdesign />} />
          <Route path='/software-products' element={<SoftwareProducts />} />
          <Route path='/eCommerce-website-development' element={<ECommerceWebsiteDevelopment />} />
          <Route path='/email-templates' element={< EmailTemplates />} />
          <Route path='/cms-website-development' element={< CMSWebsiteDevelopment />} />
          <Route path='/open-source-development' element={< OpenSourceDevelopment />} />
          <Route path='/payment-gateway-integration' element={< PaymentGatewayIntegration />} />
          <Route path='/content-writing' element={<ContentWriting />} />
          <Route path='/advertising' element={<SearchEngineMarketing />} />
          <Route path='/social-media-marketing' element={< SocialMediaMarketing />} />
          <Route path='/search-engine-optimization' element={<SearchEngineOptimization />} />
          <Route path='/domain-registration' element={<Domain />} />
          <Route path='/vps-hosting' element={< VPSHosting />} />
          <Route path='/wordpress-hosting' element={< WordpressHosting />} />
          <Route path='/cloud-server' element={< CloudServer />} />
          <Route path='/Training-and-placements-courses' element={< SoftwareTrainings />} />
          <Route path='/data-analytics' element={< DataAnalytics />} />
          <Route path='/product-designs' element={< ProductsDesign />} />
          <Route path='/careers' element={< JobPortal />} />
          <Route path='/Training-and-placements-internships' element={< Internship />} />
          <Route path='/branding' element={< Branding />} />
          <Route path='/contact-info' element={< ContactInfo />} />
          <Route path='/loginsignup' element={< LoginSignup/>} />
          <Route path='/terms-and-conditions' element={< TermsConditions/>} />
          <Route path='/privacy-policy' element={< Privacypolicy/>} />
          <Route path='/R&D' element={< RD/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
