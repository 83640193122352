import React from 'react'
import Hero from '../Components/Hero/Hero'
import Services from '../Components/Services/Services'
import Content from '../Components/Content/Content'
import Consultant from '../Components/Consultant/Consultant'
import Contact from '../Components/Contact/Contact'
import Meta from '../Components/Meta/Meta';
import Blog from '../Components/Blog'

const Home = () => {
  return (
    <div>
      <Meta
        title="Expert IT Services Customized to Your Needs | PCL Infotech"
        description="Maximize your company's potential with experienced IT solutions such as custom software, mobile apps, and digital marketing. Get a free consultation!"
      />

      <Hero />
      <Services />
      <Content />
      <Consultant />
      <Blog />
      <Contact />
    </div>
  )
}

export default Home
