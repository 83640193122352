import React, { useState } from 'react';

import web_pay_img_2 from './Assets/Payment Gateway Integration/LaptopLounge-open-source-software.webp';
import web_pay_img_3 from './Assets/Payment Gateway Integration/Clickable-Canvas-open-source-software.webp';
import comprehensive_payment_solutions from './Assets/Payment Gateway Integration/Comprehensive-Payment-Solutions-open-source-software.webp';
import security_first from './Assets/Payment Gateway Integration/Security-First-open-source-software.webp';
import seamless_user_experience from './Assets/Payment Gateway Integration/Seamless-User-Experience-open-source-software.webp';
import support_for_recurring_payments from './Assets/Payment Gateway Integration/Support-For-Recurring-Payments-open-source-software.webp';
import global_payment_options from './Assets/Payment Gateway Integration/Global-Payment-Options-open-source-software.webp';
import compliance_and_best_practices from './Assets/Payment Gateway Integration/Compliance-And-Best-Practices-open-source-software.webp';
import ongoing_support_and_optimization from './Assets/Payment Gateway Integration/Ongoing-Support-And-Optimization-open-source-software.webp';
import razorpay_logo from './Assets/Payment Gateway Integration/Razorpay-open-source-software.webp';
import paytm_logo from './Assets/Payment Gateway Integration/Paytm-open-source-software.webp';
import rupay_logo from './Assets/Payment Gateway Integration/Rupay-open-source-software.webp';
import visa_logo from './Assets/Payment Gateway Integration/Visa-open-source-software.webp';
import paypal_logo from './Assets/Payment Gateway Integration/PayPal-open-source-software.webp';
import phonepe_logo from './Assets/Payment Gateway Integration/Phonepe-open-source-software.webp';

const PaymentGatewayIntegration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Payment gateway integration
          </h1>
          <p className="text-lg max-w-xl mb-8">
            In today's digital economy, secure and reliable payment processing is crucial for the success of any online business. At PCL Infotech, we specialize in integrating trusted payment gateways into your website, ensuring that your customers can complete transactions seamlessly and safely.
          </p>
          <button
            onClick={openModal}
            className="w-74 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Payment Gateway Integration
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_pay_img_2}
            alt="Payment gateway integration"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_pay_img_3}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            By offering quick and efficient payment options while maintaining the highest standards of security. By focusing on seamless implementation and robust protection measures, we not only streamline your payment processes but also build customer confidence and trust in your brand.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's Payment Gateway Integration
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Payment Gateway Integration Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Comprehensive Payment Solutions",
              image: comprehensive_payment_solutions,
              description: `We support a wide range of payment gateways, including PayPal, Stripe, Authorize.Net, and regional payment providers, ensuring that your website can process transactions no matter where your customers are located.`
            },
            {
              title: "Security First",
              image: security_first,
              description: `Protecting your customers’ financial information is our top priority. We implement advanced security measures, including SSL encryption, PCI compliance, and fraud detection, to ensure that every transaction is secure and your customers’ data is protected.`
            },
            {
              title: "Seamless User Experience",
              image: seamless_user_experience,
              description: `We focus on creating a smooth and user-friendly payment gateway process that minimizes friction and reduces cart abandonment. From one-click payments to multi-currency support, we tailor the payment experience to meet your customers’ needs.`
            },
            {
              title: "Support for Recurring Payments",
              image: support_for_recurring_payments,
              description: `For subscription-based businesses, we integrate solutions that handle recurring payments efficiently, ensuring that billing cycles are automated, and customers receive timely notifications.`
            },
            {
              title: "Global Payment Options",
              image: global_payment_options,
              description: `If your business operates internationally, we can integrate payment gateways that support multiple currencies and local payment methods, making it easy for customers around the world to do business with you.`
            },
            {
              title: "Compliance and Best Practices",
              image: compliance_and_best_practices,
              description: `We ensure that all payment gateway integration complies with the latest industry standards and regulations, helping you avoid legal issues and maintain a trusted relationship with your customers.`
            },
            {
              title: "Ongoing Support",
              image: ongoing_support_and_optimization,
              description: `After integration, we provide ongoing support to monitor and optimize your payment gateway system, ensuring it continues to meet your needs as your business grows and evolves.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Payment Partners Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Our Payment Partners
          </h2>
          <p className="text-lg mb-12">
            We work with trusted payment gateway providers to ensure secure and reliable transactions for your business.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-items-center">
            {[
              razorpay_logo,
              paytm_logo,
              rupay_logo,
              visa_logo,
              paypal_logo,
              phonepe_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Payment Partner ${index + 1}`} className="w-24 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}

    </div>
  );
};

export default PaymentGatewayIntegration;