import React from 'react';
import internship_ban from '../Assets/internship_ban.jpg';
import elevate_your_business from '../Assets/home/Ready-To-Elevate-Your-Business-IT-Service.webp';
import our_mission from '../Assets/home/Success is Our Mission-IT-Service.webp';
import vision_into_reality from '../Assets/home/Transform-Your-Vision-into-Reality-IT-Service.webp';

const Content = () => {
  return (
    <div className="container mx-auto px-4 py-8 md:py-12 lg:py-16">
      {/* Main Grid Layout */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8 lg:gap-12">
        {/* Left Column - Banner and Introduction */}
        <div className="flex flex-col space-y-6 md:space-y-8">
          {/* Banner Image Container with Modern Styling */}
          <div className="relative group">
            {/* Image Wrapper */}
            <div className="relative overflow-hidden rounded-2xl shadow-lg 
                          md:aspect-[4/3] lg:aspect-video
                          transform transition-all duration-500">
              <img
                src={internship_ban}
                alt="Internship Banner"
                className="w-full h-full object-cover object-center 
                         transform transition-transform duration-500 
                         group-hover:scale-105"
              />
              {/* Overlay Gradient */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent 
                            opacity-0 group-hover:opacity-100 
                            transition-opacity duration-500"/>
            </div>

            {/* Optional Image Caption */}
            <div className="absolute bottom-0 left-0 right-0 p-6
                          transform translate-y-2 opacity-0 
                          group-hover:translate-y-0 group-hover:opacity-100
                          transition-all duration-500">
              <h3 className="text-white text-xl md:text-2xl font-bold 
                           drop-shadow-lg">
              </h3>
            </div>
          </div>

          {/* Introduction Card */}
          <div className="bg-white p-6 md:p-8 
                        backdrop-blur-sm bg-white/90">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6">
              <span className="text-gray-800">Pioneering Future Technologies at</span>
              <span className="block mt-1 text-blue-600">PCL Infotech (R & D)</span>
            </h2>

            <div className="space-y-4 text-base md:text-lg">
              <p className="text-gray-700 leading-relaxed">
                At PCL Infotech, we are not only focused on delivering cutting-edge IT solutions but are also committed to exploring and advancing future technologies. Our Research and Development (R&D) division actively investigates emerging fields such as Quantum Computing, Artificial Intelligence (AI), Blockchain, and more. We strive to stay ahead of the curve by innovating and experimenting with these transformative technologies, aiming to shape the digital landscape of tomorrow.
              </p>
              <p className="text-gray-700 leading-relaxed">
                We believe that true innovation comes from collaboration. That’s why we are open to collaborative R&D opportunities with businesses, academic institutions, and tech pioneers. By working together, we can push the boundaries of technology, unlock new possibilities, and create solutions that will define the future.
              </p>
            </div>
          </div>
        </div>

        {/* Right Column - Feature Cards */}
        <div className="flex flex-col space-y-6">
          {/* Elevate Your Business Card */}
          <div className="bg-white rounded-xl shadow-lg p-6 md:p-8 transform hover:-translate-y-1 hover:shadow-xl transition-all duration-300">
            <div className="flex flex-col md:flex-row gap-4 md:gap-6">
              <div className="flex-shrink-0">
                <img
                  src={elevate_your_business}
                  alt="Elevate Your Business"
                  className="w-16 h-16 md:w-20 md:h-20 object-contain rounded-lg"
                />
              </div>
              <div>
                <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-600 mb-4">
                  Ready to Elevate Your Business With IT Services
                </h1>
                <p className="text-gray-700 leading-relaxed">
                  At PCL Infotech, we believe that the right digital strategy can transform your business and fuel unprecedented growth. Our expert team is committed to crafting tailored IT solutions that not only meet your current needs but also anticipate future challenges. Let us partner with you to create a roadmap for success that leverages cutting-edge technology and innovative practices
                </p>
              </div>
            </div>
          </div>

          {/* Our Mission Card */}
          <div className="bg-white rounded-xl shadow-lg p-6 md:p-8 transform hover:-translate-y-1 hover:shadow-xl transition-all duration-300">
            <div className="flex flex-col md:flex-row gap-4 md:gap-6">
              <div className="flex-shrink-0">
                <img
                  src={our_mission}
                  alt="Our Mission"
                  className="w-16 h-16 md:w-20 md:h-20 object-contain rounded-lg"
                />
              </div>
              <div>
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-600 mb-4">
                  Your Success is Our Mission
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  We don’t just deliver IT services; we build lasting partnerships based on trust, excellence, and shared goals. With PCL Infotech, you gain a strategic ally dedicated to driving your business forward. Whether you’re looking to streamline operations, enhance security, or expand your digital presence, we’re here to guide you every step of the way with our comprehensive IT support.
                </p>
              </div>
            </div>
          </div>

          {/* Vision Into Reality Card */}
          <div className="bg-white rounded-xl shadow-lg p-6 md:p-8 transform hover:-translate-y-1 hover:shadow-xl transition-all duration-300">
            <div className="flex flex-col md:flex-row gap-4 md:gap-6">
              <div className="flex-shrink-0">
                <img
                  src={vision_into_reality}
                  alt="Vision Into Reality"
                  className="w-16 h-16 md:w-20 md:h-20 object-contain rounded-lg"
                />
              </div>
              <div>
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-600 mb-4">
                  Transform Your Vision into Reality
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  Harness the power of technology with PCL Infotech and unlock new opportunities for your business. Let’s collaborate to create a future where your business thrives in an ever-evolving digital landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;