import React, { useState } from 'react';

import web_app_img_2 from './Assets/Mobile app development/Mobile-App-Development.webp'
import web_app_img_3 from './Assets/Mobile app development/DevWrench-Mobile-App-Development.webp'
import custom_solutions_tailored from './Assets/Mobile app development/Custom-Solutions-Tailored-To-Your-Needs-Mobile-App-Development.webp'
import cross_platform_expertise from './Assets/Mobile app development/Cross-Platform-Expertise-Mobile-App-Development.webp'
import user_centric_design from './Assets/Mobile app development/User-Centric-Design-Mobile-App-Development.webp'
import agile_development_process from './Assets/Mobile app development/Agile-Development-Process-Mobile-App-Development.webp'
import seamless_integration from './Assets/Mobile app development/Seamless-Integration-Mobile-App-Development.webp'
import scalable_architecture from './Assets/Mobile app development/Scalable-Architecture-Mobile-App-Development.webp'
import end_to_end_development from './Assets/Mobile app development/End-To-End-Development-Mobile-App-Development.webp'
import post_launch from './Assets/Mobile app development/Post-Launch-Support-and-Maintenance-Mobile-App-Development.webp'
import flutter_logo from './Assets/Mobile app development/Flutter-Mobile-App-Development.webp'
import react_logo from './Assets/Mobile app development/React-Mobile-App-Development.webp'
import android_logo from './Assets/Mobile app development/Android-Studio-Mobile-App-Development.webp'
import xcode_logo from './Assets/Mobile app development/Xcode-Mobile-App-Development.webp'

const MobileAppDevelopment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Mobile App development
          </h1>
          <p className="text-lg max-w-xl mb-8">
            In today's mobile-first world, having a robust and intuitive mobile app is essential for staying ahead of the competition. At PCL Infotech, we specialize in mobile app development that transforms your innovative ideas into powerful tools for engaging your audience and driving business success.
          </p>
          <button
            onClick={openModal}
            className="w-64 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Mobile Development
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_app_img_2}
            alt="Mobile app development"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_app_img_3}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            Whether you need a custom app for iOS, Android, or both, our expert development team ensures that your app is not only fully functional but also user-friendly, aesthetically pleasing, and strategically aligned with your business goals.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's Mobile App Development
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your E-commerce Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Custom Solutions Tailored",
              image: [custom_solutions_tailored],
              description: `We recognize that each business has its own distinct needs, which is why our app development solutions are tailored to meet your specific requirements. Whether you need a simple utility app or a complex enterprise solution, we build apps that are designed to solve your challenges and achieve your objectives.`
            },
            {
              title: "Cross-Platform Expertise",
              image: [cross_platform_expertise],
              description: `Our development team specializes in creating applications for both iOS and Android platforms, ensuring a unified and smooth user experience across all devices. This approach helps you expand your reach and enhance your presence in the market.`
            },
            {
              title: "User-Centric Design",
              image: [user_centric_design],
              description: `A great app isn’t just about functionality; it’s about the experience. We prioritize user-centric design, ensuring that your app is intuitive, easy to navigate, and visually appealing. We prioritize crafting a compelling and enjoyable user experience that motivates users to revisit and engage with your app consistently.`
            },
            {
              title: "Agile Development Process",
              image: [agile_development_process],
              description: `: We employ an agile development methodology, allowing us to adapt to changes quickly and efficiently. This method guarantees timely app development, incorporating consistent updates and feedback cycles to fine-tune features and resolve any challenges encountered throughout the process.`
            },
            {
              title: "Seamless Integration",
              image: [seamless_integration],
              description: `Whether your app needs to integrate with existing systems, third-party services, or other apps, we handle the technical complexities to ensure smooth and secure integration. Our solutions are built to work seamlessly within your current digital ecosystem.`
            },
            {
              title: "Scalable Architecture",
              image: [scalable_architecture],
              description: `As your business expands, your app needs to evolve alongside it. We design and develop apps with scalability in mind, ensuring that they can handle increased user loads, new features, and expanding data without compromising performance.`
            },
            {
              title: "End-to-End Development",
              image: [end_to_end_development],
              description: `From initial concept and wireframing to development, testing, and deployment, we manage the entire mobile application development lifecycle. Our comprehensive approach ensures that every aspect of your app is thoughtfully crafted, executed, and fine-tuned for peak performance.`
            },
            {
              title: "Post-Launch Support",
              image: [post_launch],
              description: `Our commitment to your app doesn’t end at launch. We offer continuous support and maintenance to keep your app current, secure, and performing at its best. Whether you need feature updates, bug fixes, or performance enhancements, we’re here to help your app continue to succeed.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Elevate Your Brand with PCL Infotech's e-Commerce web development Services
          </h2>
          <p className="text-lg mb-12">
            At PCL Infotech, we believe that great design is about more than just looks—it's about creating a meaningful connection with your audience.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
            {[
              [flutter_logo],
              [react_logo],
              [android_logo],
              [xcode_logo]
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileAppDevelopment;