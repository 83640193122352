import React, { useState } from 'react';
import Modal from 'react-modal';
import web_ctn_img_2 from './Assets/web_ctn_img_2.png';
import web_ctn_img_3 from './Assets/web_ctn_img_3.png';
import tailored_content_strategy from './Assets/tailored_content_strategy.png';
import seo_optimization from './Assets/seo_optimization.png';
import engaging_and_persuasive_copy from './Assets/engaging_and_persuasive_copy.png';
import diverse_content_types from './Assets/diverse_content_types.png';
import research_driven_writing from './Assets/research_driven_writing.png';
import conversion_focused_approach from './Assets/conversion_focused_approach.png';
import ongoing_content_support from './Assets/ongoing_content_support.png';
import grammarly_logo from './Assets/grammarly_logo.png';
import buzzsumo_logo from './Assets/buzzsumo_logo.png';
import unsplash_logo from './Assets/unsplash_logo.png';
import semrush_logo from './Assets/semrush_logo.png';
import google_tre_logo from './Assets/google_tre_logo.png';
import giphy_logo from './Assets/giphy_logo.png';


const ContentWriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Content Writing
          </h1>
          <p className="text-lg max-w-xl mb-8">
            In the digital age, content reigns supreme, and at PCL Infotech, we harness the power of expertly crafted words to shape perceptions and drive success. Our professional content writing services are meticulously designed to deliver engaging and persuasive content that informs, captivates, and motivates your audience.
          </p>
          <button
            onClick={openModal}
            className="w-84 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build  Content Writing Services
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_ctn_img_2}
            alt="Content Writing Illustration"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_ctn_img_3}
            alt="Content Strategy Illustration"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            We understand that every word matters in capturing attention, building brand identity, and achieving your business objectives. By focusing on quality and relevance, we create content that resonates with your target audience and enhances your online presence.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's Content Writing Service
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that drives sustainable business success.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Content Writing Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Tailored Content Strategy",
              image: tailored_content_strategy,
              description: "We develop a comprehensive content strategy that aligns with your business objectives, resonating with your audience."
            },
            {
              title: "SEO Optimization",
              image: seo_optimization,
              description: "Our SEO content writing team crafts content with SEO best practices to boost your online presence."
            },
            {
              title: "Engaging and Persuasive Copy",
              image: engaging_and_persuasive_copy,
              description: "Our content is engaging and persuasive, using strong calls to action to motivate your audience to take action."
            },
            {
              title: "Diverse Content Types",
              image: diverse_content_types,
              description: "From website copy to blog posts, we tailor each piece to suit its purpose, ensuring effective communication."
            },
            {
              title: "Research-Driven Writing",
              image: research_driven_writing,
              description: "We ensure your content is accurate and relevant through rigorous research on industry trends and preferences."
            },
            {
              title: "Conversion-Focused Approach",
              image: conversion_focused_approach,
              description: "We create content that guides your audience through their decision-making journey, addressing pain points and offering solutions."
            },
            {
              title: "Ongoing Content Support",
              image: ongoing_content_support,
              description: "We provide regular updates and support for your content strategy to keep your brand voice strong and clear."
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Elevate Your Brand with PCL Infotech's Content Writing Services
          </h2>
          <p className="text-lg mb-12">
            At PCL Infotech, we believe that great design is about more than just looks—it's about creating a meaningful connection with your audience.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 items-center justify-items-center">
            {[
              grammarly_logo,
              buzzsumo_logo,
              unsplash_logo,
              semrush_logo,
              google_tre_logo,
              giphy_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center"
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-[#004aad] text-white py-2 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Send Message
          </button>
          {isSubmitted && (
            <p className="text-green-600 text-center">Thank you! Your form has been submitted.</p>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default ContentWriting;