import React, { useState } from 'react';
import dtg_sem_img_2 from './Assets/dtg_sem_img_2.png';
import dtg_sem_img_3 from './Assets/dtg_sem_img_3.png';
import dtg_sem_img_4 from './Assets/dtg_sem_img_4.png';
import targeted_traffic from './Assets/targeted_traffic.png';
import custom_ppc_campaigns from './Assets/custom_ppc_campaigns.png';
import data_driven_optimization from './Assets/data_driven_optimization.png';
import comprehensive_reporting from './Assets/comprehensive_reporting.png';
import landing_page_optimization from './Assets/landing_page_optimization.png';
import continuous_support from './Assets/continuous_support.png';
import ad_budget_management from './Assets/ad_budget_management.png';
import google_ads_logo from './Assets/google_ads_logo.png';
import dtg_sem_img_5 from './Assets/dtg_sem_img_5.png';
import dtg_sem_img_6 from './Assets/dtg_sem_img_6.png';
import dtg_sem_img_7 from './Assets/dtg_sem_img_7.png';
import dtg_sem_img_8 from './Assets/dtg_sem_img_8.png';
import dtg_sem_img_9 from './Assets/dtg_sem_img_9.png';
import dtg_sem_img_10 from './Assets/dtg_sem_img_10.png';
import targeted_campaigns from './Assets/targeted_campaigns.png';
import creative_excellence from './Assets/creative_excellence.png';
import data_driven_strategy from './Assets/data_driven_strategy.png';
import platform_expertise from './Assets/platform_expertise.png';
import meta_logo from './Assets/meta_logo.png';
import x_logo from './Assets/x_logo.png';





const SearchEngineMarketing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  return (
    <div className="w-full min-h-screen bg-white">
      {/* Main Title Section */}
      <div className="flex flex-col items-center justify-center text-center mt-8 px-4">
        <h2 className="text-3xl font-bold mb-4">Advertising</h2>
        <p className="max-w-4xl text-lg mb-8">
          Advertising is the art of capturing attention and inspiring action. By blending creativity with strategic messaging, it connects products with people's desires and needs. Effective ads don't just promote—they persuade and engage.
        </p>
      </div>

      {/* SEM Services Section */}
      <div className="text-center">
        <h2 className="text-3xl font-bold">Search Engine Marketing (SEM) Services</h2>
      </div>

      {/* Service Images and Description */}
      <div className="flex flex-col md:flex-row items-center justify-center gap-2 mb-38 px-4">
        <img src={dtg_sem_img_2} alt="SEM Service 1" className="w-full md:w-1/3 h-auto" />
        <p className="text-lg text-center md:text-left max-w-2xl">
          Looking to boost your online visibility and drive targeted traffic quickly? Our Search Engine Marketing (SEM) services at PCL Infotech are designed to help your business achieve just that.
        </p>
        <img src={dtg_sem_img_3} alt="SEM Service 2" className="w-full md:w-1/3 h-auto" />
      </div>

      {/* CTA Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-white text-black px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          Build my Search Engine Marketing (SEM) Services
        </button>
      </div>

      {/* Services Description */}
      <div className="text-center px-4 mt-12 max-w-4xl mx-auto">
        <p className="text-lg">
          We specialize in creating, managing, and optimizing effective Pay-Per-Click (PPC) campaigns that deliver measurable results aligned with your business goals. Whether you're aiming to generate leads, increase sales, or enhance brand awareness, our SEM strategies ensure you get the maximum return on investment (ROI).
        </p>
      </div>

      {/* Transform Section */}
      <div className="text-center px-4 mt-16">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          Transform Your Online Business with PCL Infotech's Search Engine Marketing (SEM) Services
        </h2>
        <p className="max-w-4xl mx-auto text-lg">
          At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth. Let us be your partner in creating a powerful, user-friendly online store that converts visitors into loyal customers and drives sustained business success.
        </p>
      </div>

      {/* Why Choose Us Section */}
      <div className="text-center mt-16">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 px-4">
          <img src={dtg_sem_img_4} alt="Why Choose Us" className="w-64 h-auto" />
          <h2 className="text-2xl font-bold">Why Choose PCL Infotech for Your Search Engine Marketing (SEM) Services Needs</h2>
        </div>
      </div>

      {/* Service Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mt-16 max-w-7xl mx-auto">
        {/* Card 1 - Targeted Traffic */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={targeted_traffic} alt="Targeted Traffic" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Targeted Traffic</h3>
          <p className="text-center">
            We focus on attracting the right audience to your website. By targeting specific keywords and demographics, we ensure that your ads reach potential customers who are most likely to convert, driving high-quality traffic that aligns with your business objectives.
          </p>
        </div>

        {/* Card 2 - Custom PPC */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={custom_ppc_campaigns} alt="Custom PPC" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Custom PPC Campaigns</h3>
          <p className="text-center">
            No two businesses are the same, which is why we tailor our PPC campaigns, including Google AdWords and Ad Manager, to meet your unique needs. From keyword research and ad creation to bid management and A/B testing, we customize every aspect of your campaign to maximize performance and ROI.
          </p>
        </div>

        {/* Card 3 - Data-Driven */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={data_driven_optimization} alt="Data Driven" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Data-Driven Optimization</h3>
          <p className="text-center">
            We continuously monitor and analyze your campaign's performance, using real-time data to make informed adjustments that improve results.
          </p>
        </div>

        {/* Card 4 - Reporting */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={comprehensive_reporting} alt="Reporting" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Comprehensive Reporting</h3>
          <p className="text-center">
            Transparency is central to our method. We offer comprehensive and straightforward reports that clearly illustrate the performance of your campaigns. You’ll have full visibility into metrics like impressions, clicks, conversions, and ROI, so you can see the tangible impact of our efforts.
          </p>
        </div>

        {/* Card 5 - Landing Page */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={landing_page_optimization} alt="Landing Page" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Landing Page Optimization</h3>
          <p className="text-center">
            SEM is an ongoing process, and we’re here to support you every step of the way. Our team provides continuous consultation and support, helping you navigate the complexities of search engine marketing and make informed decisions that drive success.
          </p>
        </div>

        {/* Card 6 - Support */}
        <div className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
          <img src={continuous_support} alt="Support" className="w-24 h-24 mb-6" />
          <h3 className="text-xl font-bold mb-4">Continuous Support</h3>
          <p className="text-center">
            We manage your ad spending with precision, ensuring that your budget is allocated effectively across your campaigns. Our goal is to maximize your ROI while minimizing unnecessary expenses, helping you achieve the best possible results within your budget.
          </p>
        </div>
      </div>

      {/* Contact Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          CONTACT US
        </button>
      </div>

      {/* Social Media Section */}
      <div className="mt-24 px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Social Media Ads</h2>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center p-6 gap-4 mt-8">
          <img src={dtg_sem_img_5} alt="Social Media 1" className="w-full md:w-1/4 h-auto" />
          <p className="text-xl text-center font-semibold">
            Social Media Ads for IT Solutions – Boost Your Presence on Facebook, Instagram, Twitter, and LinkedIn
          </p>
          <img src={dtg_sem_img_6} alt="Social Media 2" className="w-full md:w-1/3 h-auto" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 mt-16 p-18 max-w-6xl mx-auto">
        {/* Facebook Ads */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="text-xl font-bold mb-4">Facebook Ads – Precision Targeting for IT Services</h3>
          <p className="mb-4">
            Facebook provides advanced targeting options based on user interests, behaviors, and demographics such as cloud computing, cybersecurity, and software development.
          </p>
          <div className="w-3/4 md:w-2/3 mx-auto md:mx-0">
            <img src={dtg_sem_img_7} alt="Facebook Ads" className="w-full h-auto" />
          </div>
        </div>

        {/* Instagram Ads */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="text-xl font-bold mb-4">Instagram Ads – Visually Captivating Content</h3>
          <p className="mb-4">
            Instagram's visual-centric platform is ideal for creatively showcasing your IT solutions. Create engaging visual content that resonates with your audience.
          </p>
          <div className="w-3/4 md:w-2/3 mx-auto md:mx-0">
            <img src={dtg_sem_img_8} alt="Instagram Ads" className="w-full h-auto" />
          </div>
        </div>

        {/* X Ads */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="text-xl font-bold mb-4">X Ads – Real-Time Engagement for IT Promotions</h3>
          <p className="mb-4">
            Perfect for promoting time-sensitive offers, product launches, or tech events. Create compelling sponsored tweets that share impactful messages.
          </p>
          <div className="w-3/4 md:w-2/3 mx-auto p-4 md:mx-0">
            <img src={dtg_sem_img_9} alt="X Ads" className="w-full h-auto" />
          </div>
        </div>

        {/* LinkedIn Ads */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="text-xl font-bold mb-4">LinkedIn Ads – B2B Targeting for IT Professionals</h3>
          <p className="mb-4">
            As the leading platform for B2B marketing, LinkedIn offers direct access to professionals and decision-makers in the tech industry.
          </p>
          <div className="w-3/4 md:w-2/3 mx-auto md:mx-0">
            <img src={dtg_sem_img_10} alt="LinkedIn Ads" className="w-full h-auto" />
          </div>
        </div>
      </div>


      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Technology Stack We Consider for Web Design
          </h2>
          <p className="text-lg mb-12">
            Our team of certified web designers is competent to work on different tools and platforms that leverage our clients with the flexibility to opt from choices.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-3 gap-8 items-center justify-items-center">
            {[
              [google_ads_logo],
              [meta_logo],
              [x_logo]
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default SearchEngineMarketing; 