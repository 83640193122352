import React, { useState } from 'react';

// Make sure to import all images
import web_ecom_img_2 from './Assets/E-Commerce website development/E-commerce-Affiliate-Mastery-E-commerce-web-development.webp'
import web_ecom_img_3 from './Assets/web_ecom_img_3.png';
import custom_Ecommerce_solutions from './Assets/custom_Ecommerce_solutions.png';
import user_friendly_shopping_experience from './Assets/user_friendly_shopping_experience.png';
import advanced_payment_integration from './Assets/advanced_payment_integration.png';
import comprehensive_inventory_management from './Assets/comprehensive_inventory_management.png';
import mobile_optimized_design from './Assets/mobile_optimized_design.png';
import seo_and_marketing_tools from './Assets/seo_and_marketing_tools.png';
import scalable_and_flexible_platforms from './Assets/scalable_and_flexible_platforms.png';
import seamless_third_party_integrations from './Assets/seamless_third_party_integrations.png';
import robust_security_features from './Assets/robust_security_features.png';
import ongoing_support from './Assets/ongoing_support.png';
import shopify_logo from './Assets/shopify_logo.png';
import mailchimp_logo from './Assets/mailchimp_logo.png';
import canva_logo from './Assets/canva_logo.png';
import woo_logo from './Assets/woo_logo.png';
import magento_logo from './Assets/magento_logo.png';
import google_analytics_logo from './Assets/google_analytics_logo.png';


const ECommerceWebsiteDevelopment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            E-commerce Website Development
          </h1>
          <p className="text-lg max-w-xl mb-8">
            In the fiercely competitive online marketplace, your e-commerce platform can make or break your success. At PCL Infotech, we specialize in creating powerful, scalable e-commerce websites.
          </p>
          <button
            onClick={openModal}
            className="w-64 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my E-Commerce Website
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_ecom_img_2}
            alt="E-commerce development"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_ecom_img_3}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            That are designed to drive sales, enhance customer satisfaction, and build brand loyalty. Our comprehensive solutions cover every aspect of your online store, ensuring that your platform not only looks great but also functions seamlessly.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's E-commerce Solutions
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your E-commerce Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Custom E-commerce Solutions",
              image: custom_Ecommerce_solutions,
              description: `We recognize that each business has distinct needs. We provide bespoke e-commerce solutions designed to align with your unique business model, target demographic, and sales goals. Whether you're offering physical products, digital downloads, or services, we build a platform that perfectly suits your requirements when developing an e-commerce website.`
            },
            {
              title: "User-Friendly Shopping Experience",
              image: user_friendly_shopping_experience,
              description: `We focus on delivering an intuitive and engaging shopping experience that keeps customers coming back. Your website is carefully crafted to boost conversions and reduce cart abandonment, with intuitive product categories and an efficient checkout process at its core.`
            },
            {
              title: "Advanced Payment Integration",
              image: advanced_payment_integration,
              description: `Security and user convenience are crucial for success in B2C e-commerce. We integrate secure, reliable payment gateways that support multiple payment methods, ensuring a smooth transaction process for your customers while protecting sensitive data with robust encryption.`
            },
            {
              title: "Comprehensive Inventory Management",
              image: comprehensive_inventory_management,
              description: `Efficient inventory management is crucial for the smooth operation of your online store. Our solutions include powerful inventory management tools that allow you to track stock levels, manage orders, and automate reordering, helping you stay organized and avoid stakeouts.`
            },
            {
              title: "Mobile-Optimized Design",
              image: mobile_optimized_design,
              description: `With a significant portion of B2C e-commerce transactions now occurring on mobile devices, we ensure your e-commerce website is fully responsive and optimized for mobile users. This guarantees a seamless shopping experience across all devices, whether you're using a desktop or a smartphone.`
            },
            {
              title: "SEO and Marketing Tools",
              image: seo_and_marketing_tools,
              description: `Visibility is key to success in e-commerce web development. We create search-engine-optimized websites designed to perform well in rankings, bringing more organic visitors to your online store. We also incorporate key marketing tools like email campaigns, social media engagement, and customer reviews to help you draw in and retain your audience.`
            },
            {
              title: "Scalable and Flexible Platforms",
              image: scalable_and_flexible_platforms,
              description: `As your business grows, your e-commerce platform needs to adapt. Our solutions are built for growth, enabling you to effortlessly integrate new products, features, and functionalities while maintaining optimal performance.`
            },
            {
              title: "Seamless Third-Party Integrations",
              image: seamless_third_party_integrations,
              description: `We offer seamless integration with third-party tools and services, such as CRM systems, ERP software, and shipping providers, ensuring that your e-commerce platform works harmoniously with the rest of your business operations.`
            },
            {
              title: "Robust Security Features",
              image: robust_security_features,
              description: `Protecting your customers' data and your business is our top priority. We deploy cutting-edge security protocols such as SSL certificates, data encryption, and fraud detection to protect your website from cyber threats while ensuring adherence to industry regulations.`
            },
            {
              title: "Ongoing Support",
              image: ongoing_support,
              description: `Launching your e-commerce website is only the first step. We offer continuous support and maintenance to ensure your platform operates efficiently, address any challenges quickly, and keep your site current with the latest technologies and trends.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Elevate Your Brand with PCL Infotech's e-Commerce web development Services
          </h2>
          <p className="text-lg mb-12">
            At PCL Infotech, we believe that great design is about more than just looks—it's about creating a meaningful connection with your audience.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 items-center justify-items-center">
            {[
              shopify_logo,
              mailchimp_logo,
              canva_logo,
              woo_logo,
              magento_logo,
              google_analytics_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>


      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}


    </div>
  );
};

export default ECommerceWebsiteDevelopment;