import React, { useState } from 'react';

import web_dev_img_2 from './Assets/web_dev_img_2.png';
import web_dev_img_3 from './Assets/web_dev_img_3.png';
import tailored_to_your_business_needs from './Assets/tailored_to_your_business_needs.png';
import cutting_edge_technology from './Assets/cutting_edge_technology.png';
import responsive_and_mobile_optimized from './Assets/responsive_and_mobile_optimized.png';
import ongoing_support_and_maintenance from './Assets/ongoing_support_and_maintenance.png';
import data_security_and_compliance from './Assets/data_security_and_compliance.png';
import scalable_solutions_for_growth from './Assets/scalable_solutions_for_growth.png';
import html_logo from './Assets/html_logo.png';
import css_logo from './Assets/css_logo.png';
import javascript_logo from './Assets/javascript_logo.png';
import react_logo from './Assets/react_logo.png';
import angular_logo from './Assets/angular_logo.png';
import next_logo from './Assets/next_logo.png';
import express_logo from './Assets/express_logo.png';
import nodejs_logo from './Assets/nodejs_logo.png';
import mongo_logo from './Assets/mongo_logo.png';
import php_logo from './Assets/php_logo.png';
import mysql_logo from './Assets/mysql_logo.png';
import postsql_logo from './Assets/postsql_logo.png';

const WebDevelopment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const features = [
    {
      title: "Tailored to Your Business Needs",
      image: tailored_to_your_business_needs,
      description: `We don't believe in one-size-fits-all. Each website we develop is fully customized to reflect your brand identity, meet your specific business objectives, and provide a unique experience for your users.`
    },
    {
      title: "Cutting-Edge Technology",
      image: cutting_edge_technology,
      description: `We leverage the latest web technologies and frameworks, including Java full-stack developer expertise, to build sites that are fast, secure, and future-proof. We ensure your website remains cutting-edge by embracing the latest technological advancements.`
    },
    {
      title: "Responsive and Mobile-Optimized",
      image: responsive_and_mobile_optimized,
      description: `With the majority of users accessing websites via mobile devices, we ensure your site is fully responsive and performs flawlessly on all screen sizes, providing a seamless experience for every visitor.`
    },
    {
      title: "Ongoing Support and Maintenance",
      image: ongoing_support_and_maintenance,
      description: `Our dedication to your success goes beyond the launch. We provide continuous support and maintenance to ensure your website remains up-to-date, secure, and optimized for peak performance.`
    },
    {
      title: "Data Security and Compliance",
      image: data_security_and_compliance,
      description: `We implement industry-leading security practices to protect your website and user data from threats. We prioritize compliance with key regulations like GDPR, protecting both your business and your customers' data.`
    },
    {
      title: "Scalable Solutions for Growth",
      image: scalable_solutions_for_growth,
      description: `As your business expands, your website will need to adapt. Our flexible solutions allow your website to evolve alongside your business, integrating additional features and functionalities as needed while maintaining optimal performance.`
    }
  ];

  const techStack = [
    html_logo,
    css_logo,
    javascript_logo,
    react_logo,
    angular_logo,
    next_logo,
    express_logo,
    nodejs_logo,
    mongo_logo,
    php_logo,
    mysql_logo,
    postsql_logo
  ];

  return (
    <div className="w-full px-4">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-10 p-2">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
          Web Development Services
          </h1>
          <p className="text-lg max-w-xl mb-8">
            At PCL Infotech, we craft high-performance, secure, and scalable websites tailored to your
            unique business objectives. Our web development services go beyond aesthetics; we focus on
            creating user-friendly, responsive, and feature-rich websites that deliver an exceptional user
            experience.
          </p>
          <button
            onClick={openModal}
            className="w-64 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Web Presence
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_dev_img_2}
            alt="Web development"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_dev_img_3}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            Our expertise includes custom e-commerce platforms, content management software (CMS),
            and more. We integrate advanced features such as SEO optimization, fast load times, and mobile
            responsiveness to ensure your site ranks well and performs seamlessly across all devices.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's Web Development
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            Whether you're launching a new site or revamping an existing one, our team works closely with you to understand your vision and translate it into a powerful online presence.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Web Development Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Technology Stack We Consider for Web Development
          </h2>
          <p className="text-lg mb-12">
            Our team of certified web developers is competent to work on different technologies and platforms that leverage our clients with the flexibility to opt from choices.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-4 gap-8 items-center justify-items-center">
            {techStack.map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}

    </div>
  );
};

export default WebDevelopment;