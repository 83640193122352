import React, { useState } from 'react'
import hs_cld_img_2 from './Assets/Cloud server hosting/Cloud-Server-hosting.webp'
import scalability_1 from './Assets/Cloud server hosting/Scalability-Cloud-Server-hosting.webp'
import reliability from './Assets/Cloud server hosting/Reliability-Cloud-Server-hosting.webp'
import security from './Assets/Cloud server hosting/Security-Cloud-Server-hosting.webp'
import cost_efficiency from './Assets/Cloud server hosting/Cost-Efficiency-Cloud-Server-hosting.webp'
import global_reach from './Assets/Cloud server hosting/Global-Reach-Cloud-Server-hosting.webp'
import easy_management from './Assets/Cloud server hosting/Easy-Management-Cloud-Server-hosting.webp'
import seamless_integration_2 from './Assets/Cloud server hosting/Seamless-Integration-Cloud-Server-hosting.webp'
import Modal from 'react-modal';


const CloudServer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => setIsSubmitted(false), 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const features = [
    {
      img: scalability_1,
      alt: "A man runs on an arrow towards a target, holding a cup, symbolizing success in cloud server hosting.",
      title: "Scalability",
      description: "Easily scale your server resources up or down based on your traffic and performance needs, ensuring optimal performance during peak times without overpaying for unused capacity."
    },
    {
      img: reliability,
      alt: "A woman sitting with a laptop, making a V sign, symbolizing cloud server hosting and digital connectivity.",
      title: "Reliability",
      description: "Our cloud servers are hosted in world-class data centers, ensuring maximum uptime and redundancy so your website or application is always available."
    },
    {
      img: security,
      alt: "A man sits at a desk with a laptop and a shield, symbolizing security in cloud server hosting.",
      title: "Security",
      description: "We implement advanced security measures, including data encryption, firewalls, and regular backups, to protect your sensitive information and keep your business safe from cyber threats."
    },
    {
      img: cost_efficiency,
      alt: "A man holds a dollar coin next to a graph, symbolizing financial growth in cloud server hosting.",
      title: "Cost Efficiency",
      description: "With flexible pricing models, you only pay for the resources you use, making cloud hosting a cost-effective solution compared to traditional on premise servers."
    },
    {
      img: global_reach,
      alt: "A hand holds a business card while running up a wall adorned with gears and icons, symbolizing cloud server hosting.",
      title: "Global Reach",
      description: "Our cloud servers can be deployed across multiple regions, giving your users fast access to your services from anywhere in the world, reducing latency and improving user experience."
    },
    {
      img: easy_management,
      alt: "Businessman meditating with cloud server icons, symbolizing focus and innovation in technology and business solutions.",
      title: "Easy Management",
      description: "Our intuitive control panels make it easy for you to manage your cloud server, configure settings, monitor performance, and scale resources—all with a few clicks."
    },
    {
      img: seamless_integration_2,
      alt: "People engaging with social media displayed on a computer screen, highlighting cloud server hosting capabilities.",
      title: "Seamless Integration",
      description: "Whether you're hosting a website, running an e-commerce platform, or deploying enterprise applications, our cloud servers integrate seamlessly with your existing infrastructure and tools."
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="bg-[#1A5EE2] px-4 py-16 md:py-20">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between gap-8 md:gap-20">
          <div className="max-w-2xl text-white">
            <h1 className="text-3xl text-white md:text-4xl text-white font-bold mb-6">Cloud Server Hosting</h1>
            <p className="text-lg mb-8">
              In today's digital era, flexibility and scalability are key to running a successful business. At PCL Infotech, we offer cutting-edge cloud server solutions that allow you to host and manage your applications, websites, and data with ease. Our cloud servers are designed to scale with your business needs, providing a secure, reliable, and high-performance environment that adapts to changing demands.
            </p>
            <button
              onClick={() => window.open("http://host.pclinfotech.com/", "_blank")}
              className="px-6 py-2 bg-white text-black font-semibold rounded-md shadow-lg hover:bg-gray-100 transition-colors"
            >
              Click here
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src={hs_cld_img_2}
              alt="Cloud computing services illustration featuring a cloud server hosting environment with interconnected data storage."
              className="w-full max-w-xl mx-auto"
            />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-16 px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center text-[#004aad] mb-12">
          Our Cloud Server Hosting Services Include
        </h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-3xl p-8 shadow-lg hover:shadow-xl transition-shadow flex flex-col items-center text-center"
            >
              <img
                src={feature.img}
                alt={feature.alt}
                className="w-24 h-auto mb-6"
              />
              <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="bg-[#004aad] text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg h-32"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-[#1A5EE2] text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              Send Message
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CloudServer;