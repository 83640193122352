import React, { useState } from 'react';
import './CSS/Branding.css';
import dtg_brd_img_2 from './Assets/dtg_brd_img_2.png';
import dtg_brd_img_3 from './Assets/dtg_brd_img_3.png';
import dtg_sem_img_4 from './Assets/dtg_sem_img_4.png';
import brand_strategy_development from './Assets/brand_strategy_development.png';
import visual_identity_creation from './Assets/visual_identity_creation.png';
import brand_messaging from './Assets/brand_messaging.png';
import consistency_across_platforms from './Assets/consistency_across_platforms.png';
import brand_positioning from './Assets/brand_positioning.png';
import brand_guidelines from './Assets/brand_guidelines.png';
import dtg_brd_img_4 from './Assets/dtg_brd_img_4.png';
import dtg_brd_img_5 from './Assets/dtg_brd_img_5.png';
import custom_design_concepts from './Assets/custom_design_concepts.png';
import brand_alignment from './Assets/brand_alignment.png';
import versatility from './Assets/versatility.png';
import feedback_and_revisions from './Assets/feedback_and_revisions.png';
import high_quality_deliverables from './Assets/high_quality_deliverables.png';
import canva_logo from './Assets/canva_logo.png';
import coreldraw_logo from './Assets/coreldraw_logo.png';
import inkscape_logo from './Assets/inkscape_logo.png';
import photoshop_logo from './Assets/photoshop_logo.png';
import ai_logo from './Assets/ai_logo.png';
import adobe_express_logo from './Assets/adobe_express_logo.png';
import Modal from 'react-modal';


const Branding = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full min-h-screen bg-white">
      {/* Main Title Section */}
      <div className="flex flex-col items-center justify-center text-center mt-8 px-4">
        <h2 className="text-3xl font-bold mb-4">Branding</h2>
        <p className="max-w-4xl text-lg mb-8">
          Your brand is more than just a logo; it's the entire narrative and experience you offer to your audience. At PCL Infotech, we provide comprehensive branding services designed to help you create a strong, cohesive brand identity that resonates with your target market.
        </p>
      </div>

      {/* Branding Services Section */}
      <div className="flex flex-col md:flex-row items-center justify-center gap-2 mb-38 px-4">
        <img src={dtg_brd_img_2} alt="Brand Image 2" className="w-full md:w-1/3 h-auto" />
        <p className="text-lg text-center md:text-left max-w-2xl">
          We take a comprehensive approach to branding, combining strategy, design, and messaging to create a memorable brand identity that connects with your audience and drives business growth.
        </p>
        <img src={dtg_brd_img_3} alt="Brand Image 3" className="w-full md:w-1/3 h-auto" />
      </div>

      {/* CTA Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-white text-black px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          Build my branding
        </button>
      </div>

      {/* Transform Section */}
      <div className="text-center px-4 mt-16">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          Transform Your Online Business with PCL Infotech's Branding
        </h2>
        <p className="max-w-4xl mx-auto text-lg">
          At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth. Let us be your partner in creating a powerful, user-friendly online store that converts visitors into loyal customers and drives sustained business success.
        </p>
      </div>

      {/* Why Choose Us Section */}
      <div className="text-center mt-16">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 px-4">
          <img src={dtg_sem_img_4} alt="Why Choose Us" className="w-64 h-auto" />
          <h2 className="text-2xl font-bold">Why Choose PCL Infotech for Your Branding Needs</h2>
        </div>
      </div>

      {/* Service Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mt-16 max-w-7xl mx-auto">
        {[
          {
            image: brand_strategy_development,
            title: "Brand Strategy Development",
            description: "We work with you to define your brand's mission, vision, and values, creating a strategic framework that guides all branding efforts."
          },
          {
            image: visual_identity_creation,
            title: "Visual Identity Creation",
            description: "From colour palettes and typography to imagery and design elements, we ensure that every visual aspect of your brand is thoughtfully designed."
          },
          {
            image: brand_messaging,
            title: "Brand Messaging",
            description: "We craft compelling brand messages and narratives that effectively communicate your brand's story and value propositions to your audience."
          },
          {
            image: consistency_across_platforms,
            title: "Consistency Across Platforms",
            description: "We ensure that your branding is consistent across all touchpoints, including your website, social media, marketing materials, and customer interactions."
          },
          {
            image: brand_positioning,
            title: "Brand Positioning",
            description: "We help you differentiate your brand from competitors by identifying your unique selling points and positioning your brand strategically in the market."
          },
          {
            image: brand_guidelines,
            title: "Brand Guidelines",
            description: "We create comprehensive brand guidelines that serve as a reference for maintaining consistency in all branding and marketing efforts."
          }
        ].map((service, index) => (
          <div key={index} className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <img src={service.image} alt={service.title} className="w-24 h-24 mb-6" />
            <h3 className="text-xl font-bold mb-4">{service.title}</h3>
            <p className="text-center">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Contact Button */}
      <div className="flex justify-center mt-12">
        <button
          onClick={openModal}
          className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-lg font-semibold"
        >
          CONTACT US
        </button>
      </div>

      {/* Logo Design Section */}
      <div className="mt-24 px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">Logo Designs</h2>
          <p className="max-w-4xl mx-auto text-lg mb-8">
            Logo is the visual cornerstone of your brand identity. At PCL Infotech, our logo design services are focused on creating logos that are not only visually appealing but also deeply aligned with your brand's essence.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center p-6 gap-4">
          <img src={dtg_brd_img_4} alt="Logo Design 1" className="w-full md:w-1/3 h-auto" />
          <p className="text-xl text-center font-semibold max-w-2xl">
            A well-designed logo is the cornerstone of your brand's identity. Our creative team designs logos that are unique, memorable, and aligned with your brand's values and messaging.
          </p>
          <img src={dtg_brd_img_5} alt="Logo Design 2" className="w-full md:w-1/3 h-auto" />
        </div>
      </div>

      {/* Logo Design Services Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mt-16 max-w-7xl mx-auto">
        {[
          {
            image: custom_design_concepts,
            title: "Custom Design Concepts",
            description: "We develop multiple logo concepts tailored to your brand's values and objectives, ensuring a design that truly represents your business."
          },
          {
            image: brand_alignment,
            title: "Brand Alignment",
            description: "We incorporate your brand's personality, mission, and target audience into the logo design, ensuring that it resonates with your market."
          },
          {
            image: versatility,
            title: "Versatility",
            description: "Our logos are designed to be versatile and scalable, maintaining their impact and legibility across various applications."
          },
          {
            image: feedback_and_revisions,
            title: "Feedback and Revisions",
            description: "We value your input throughout the design process, offering revisions to ensure that the final logo perfectly captures your vision."
          },
          {
            image: high_quality_deliverables,
            title: "High-Quality Deliverables",
            description: "We provide high-resolution logo files in various formats suitable for both digital and print use, along with usage guidelines."
          }
        ].map((service, index) => (
          <div key={index} className="flex flex-col items-center p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <img src={service.image} alt={service.title} className="w-24 h-24 mb-6" />
            <h3 className="text-xl font-bold mb-4">{service.title}</h3>
            <p className="text-center">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Tools We Use for Branding
          </h2>
          <p className="text-lg mb-12">
            We leverage industry-leading design tools and platforms to create outstanding brand identities.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-3 gap-8 items-center justify-items-center">
            <img src={canva_logo} alt="Canva" className="w-20 md:w-32 h-auto" />
            <img src={coreldraw_logo} alt="CorelDraw" className="w-20 md:w-32 h-auto" />
            <img src={inkscape_logo} alt="Inkscape" className="w-20 md:w-32 h-auto" />
            <img src={photoshop_logo} alt="Photoshop" className="w-20 md:w-32 h-auto" />
            <img src={ai_logo} alt="Adobe Illustrator" className="w-20 md:w-32 h-auto" />
            <img src={adobe_express_logo} alt="Adobe Express" className="w-20 md:w-32 h-auto" />
          </div>
        </div>
      </div>

      {/* Modal Form */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content">
        <div className="p-5">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col">
              <label className="font-semibold">First Name</label>
              <input
                className="border rounded-md p-2"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <label className="font-semibold">Last Name</label>
              <input
                className="border rounded-md p-2"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="font-semibold">Email</label>
              <input
                className="border rounded-md p-2"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label className="font-semibold">Phone Number</label>
              <input
                className="border rounded-md p-2"
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-semibold">Message</label>
              <textarea
                className="border rounded-md p-2"
                name="message"
                placeholder="Write your message.."
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="bg-blue-600 text-white rounded-md p-2">Send Message</button>
            {isSubmitted && <p className="text-green-500 mt-2">Thank you! Your form has been submitted.</p>}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Branding;