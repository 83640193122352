import React from 'react';

import s_t_banner2 from './Assets/Training and placement/Courses/Advanced-placement-2.webp'
import training from './Assets/Training and placement/Courses/Training-And-Placement-Opportunities-Advanced-placement.webp'
import business from './Assets/Training and placement/Courses/Business-Analyst-Advanced-placement.webp'
import digital from './Assets/Training and placement/Courses/Digital-Marketing-Specialist-Advanced-placement.webp'
import fullstack from './Assets/Training and placement/Courses/Full-Stack-Developer-Advanced-placement.webp'
import placement from './Assets/Training and placement/Courses/Placement-Advanced-placement.webp'
import uiux from './Assets/Training and placement/Courses/UIUXDesigner-Advanced-placement.webp'
import full_stack_develper_icon from './Assets/full_stack_developer_icon.png'
import ui_icon from './Assets/ui_icon.png'
import digital_marketing_icon from './Assets/digital_marketing_icon.png'
import data_analyst_icon from './Assets/data_analyst_icon.png'
import cyber_security_icon from './Assets/cyber_security_icon.png'
import data_science_icon from './Assets/data_science_icon.png'
import devops_icon from './Assets/devops_icon.png'
import robotics_icon from './Assets/robotics_icon.png'


const FeatureCard = ({ image, title }) => (
  <div className="flex justify-center items-center w-48 h-32 md:w-44 md:h-28 sm:w-40 sm:h-24 bg-white rounded-lg shadow-lg hover:scale-105 hover:shadow-blue-200 hover:border-b-4 hover:border-blue-600 transition-all cursor-pointer">
    <div className="flex flex-col items-center p-4 space-y-2">
      <img src={image} alt={title} className="w-12 h-12 object-contain" />
      <p className="text-center text-gray-800 font-medium text-sm">{title}</p>
    </div>
  </div>
);



const SoftwareTrainings = () => {

  const features = [
    {
      image: [full_stack_develper_icon],
      title: 'Full Stack Developer'
    },
    {
      image: [ui_icon],
      title: 'UI & UX Designer'
    },
    {
      image: [digital_marketing_icon],
      title: 'Digital Marketing'
    },
    {
      image: [data_analyst_icon],
      title: 'Data Analyst'
    },
    {
      image: [cyber_security_icon],
      title: 'Cyber Security'
    },
    {
      image: [data_science_icon],
      title: 'Data Science'
    },
    {
      image: [devops_icon],
      title: 'Devops'
    },
    {
      image: [robotics_icon],
      title: 'Robotics Automation'
    }

  ];

  return (
    <div className="relative">
      {/* Navbar - Previous code remains the same */}

      {/* Banner Image */}
      <div className="w-full ">
        <img
          src={s_t_banner2}
          alt="Training Banner"
          className="w-full h-auto object-cover"
        />
      </div>

      {/* Training and Placement Sections */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Training and Placement Opportunities Section */}
          <div className="mb-16">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="md:w-1/2">
                <h2 className="text-3xl font-bold text-gray-900 mb-4">Training and Placement Opportunities</h2>
                <p className="text-gray-600 mb-6">Take advantage of our comprehensive training programs and placement opportunities to kickstart your career in technology. Our expert-led courses are designed to help you succeed in today's competitive job market.</p>
                <button className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors">Learn More</button>
              </div>
              <div className="md:w-1/2">
                <img src={training} alt="Graduate" className="w-full" />
              </div>
            </div>
          </div>

          {/* Placements Section */}
          <div className="mb-16">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="md:w-1/2 order-2 md:order-1">
                <img src={placement} alt="Placement" className="rounded-lg shadow-lg w-full" />
              </div>
              <div className="md:w-1/2 order-1 md:order-2">
                <h2 className="text-3xl font-bold text-gray-900 mb-4">Placements</h2>
                <p className="text-gray-600 mb-6">Our placement program has successfully helped numerous students secure positions at leading companies. We provide comprehensive support throughout your job search journey.</p>
                <button className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors">View Details</button>
              </div>
            </div>
          </div>

          <section className="flex flex-col items-center px-4 sm:px-6 md:px-8 lg:px-16 py-12 md:py-16">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-600 mb-12 text-center">
              We are Offering these Training Courses
            </h2>

            <div className="w-full max-w-7xl">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
                {features.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    image={feature.image}
                    title={feature.title}
                  />
                ))}
              </div>
            </div>

            {/* Contact Form Section */}
          </section>

          {/* Course Cards */}
          <div className="grid grid--1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {/* Full Stack Developer Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={fullstack} alt="Full Stack Developer" className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Full Stack Developer</h3>
                <p className="text-gray-600 mb-4">Full stack development training and placement equips you with expertise in both front-end and back-end technologies, enabling you to develop and manage complete web applications. The training covers essential skills like HTML, CSS, JavaScript, and frameworks like React or Angular for the front end, alongside server-side languages like Node.js and databases like MongoDB or SQL for the back end. With this knowledge, you'll be ready to build dynamic, high-performance websites and applications, bridging the gap between user experience and server-side logic for seamless performance across all development layers.</p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">Learn More</button>
              </div>
            </div>

            {/* UI/UX Designer Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={uiux} alt="UI/UX Designer" className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">UI/UX Designer</h3>
                <p className="text-gray-600 mb-4">UI/UX Designers craft intuitive and visually appealing digital interfaces, focusing on user-centric design to enhance satisfaction and usability. Our training covers UI principles like layout, color theory, typography, and responsive design, alongside UX strategies such as user research, wireframing, prototyping, and usability testing. Master these skills to create designs that meet user needs and drive business success.</p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">Learn More</button>
              </div>
            </div>

            {/* Digital Marketing Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={digital} alt="Digital Marketing" className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Digital Marketing</h3>
                <p className="text-gray-600 mb-4">Digital marketing experts develop and implement strategies to enhance brand visibility and boost online engagement. Our digital marketing course with placement covers SEO, PPC, social media marketing, content marketing, and email campaigns. Master the art of data analysis, campaign optimization, and leveraging digital platforms to connect with your audience and accomplish your marketing objectives. This course equips you with strategies to boost online visibility, drive traffic, and convert leads into customers, offering scalable solutions for businesses of all sizes in the digital age.</p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">Learn More</button>
              </div>
            </div>

            {/* Business Analyst Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={business} alt="Digital Marketing" className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">Business Analyst</h3>
                <p className="text-gray-600 mb-4">Data Analysts interpret complex data to provide actionable insights for business decisions. Our data analytics course with placement covers data collection, cleaning, analysis, and visualization using tools like Excel, SQL, Python, and Power BI. This expertise helps you analyze trends, generate reports, and support data-driven strategies. Business Analysts evaluate processes, systems, and operations to identify improvements, driving efficiency, profitability, and growth. They collaborate with stakeholders, project managers, and developers to ensure effective implementation of business requirements, making them vital across industries like finance, healthcare, IT, retail, and manufacturing.</p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareTrainings;