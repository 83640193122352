import React, { useState } from 'react'
import './CSS/SoftwareProducts.css'
import web_sfw_img_2 from './Assets/Software Products Development/InnovatePro-Software-Software-Products-development.webp'
import web_sfw_img_3 from './Assets/Software Products Development/DevVision-Suite-Software-Products-development.webp'
import innovative_and_reliable from './Assets/Software Products Development/Innovative-And-Reliable-Software-Products-development.webp'
import customized_for_your_business from './Assets/Software Products Development/Customized-For-Your-Business-Software-Products-development.webp'
import seamless_integration_1 from './Assets/Software Products Development/Seamless-Integration-Software-Products-development.webp'
import user_friendly_design from './Assets/Software Products Development/User-Friendly-Design-Software-Products-development.webp'
import enterprise_resource_planning from './Assets/Software Products Development/Enterprise-Resource-Planning(ERP)-Software-Products-development.webp'
import customer_relationship_management from './Assets/Software Products Development/Customer-Relationship-Management(CRM)-Software-Products-development.webp'
import accounting_software from './Assets/Software Products Development/Accounting-Software-Products-development.webp'
import project_management_tools from './Assets/Software Products Development/Project-Management-Tools-Software-Products-development.webp'
import affordable_pricing from './Assets/Software Products Development/Affordable-Pricing-Software-Products-development.webp'
import custom_software_development from './Assets/Software Products Development/Custom-Software-Development-Software-Products-development.webp'
import support_and_training from './Assets/Software Products Development/Support-And-Training-Software-Products-development.webp'
import future_proof_solutions from './Assets/Software Products Development/Project-Management-Tools-Software-Products-development.webp'
import github_logo from './Assets/Software Products Development/Github-Software-Products-development.webp'
import angular_logo from './Assets/Software Products Development/Angular-Software-Products-development.webp'
import react_logo from './Assets/Software Products Development/React-Software-Products-development.webp'
import mongo_logo from './Assets/Software Products Development/MangoDB-Software-Products-development.webp'
import nodejs_logo from './Assets/Software Products Development/Node.js-Software-Products-development.webp'
import php_logo from './Assets/Software Products Development/PHP-Software-Products-development.webp'
import vs_logo from './Assets/Software Products Development/Visual-Studio-Code-Software-Products-development.webp'
import linux_logo from './Assets/Software Products Development/Linux-Software-Products-development.webp'
import msa from './Assets/Software Products Development/Microsoft-azure-Software-Products-development.webp'
import django from './Assets/Software Products Development/django-Software-Products-development.webp'
import Modal from 'react-modal';



const SoftwareProducts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Software Products Development
          </h1>
          <p className="text-lg max-w-xl mb-8">
            At PCL Infotech, we offer a wide range of software products designed to streamline operations, boost productivity, and empower businesses to achieve more. Whether you're a startup, small business, or an enterprise, our software solutions are built to meet your unique needs.
          </p>
          <button
            onClick={openModal}
            className="w-74 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Software Product Development
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_sfw_img_2}
            alt="Software product development"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_sfw_img_3}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            Whether you need a custom app for iOS, Android, or both, our expert development team ensures that your app is not only fully functional but also user-friendly, aesthetically pleasing, and strategically aligned with your business goals.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's Software Product Development
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Software Product Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Innovative and Reliable",
              image: innovative_and_reliable,
              description: "We leverage cutting-edge technologies to deliver innovative software products that stay ahead of industry trends."
            },
            {
              title: "Customized for Your Business",
              image: customized_for_your_business,
              description: "Our software products aren't one-size-fits-all. We understand every business is different."
            },
            {
              title: "Seamless Integration",
              image: seamless_integration_1,
              description: "Our software integrates effortlessly with your existing systems, ensuring a smooth transition."
            },
            {
              title: "User-Friendly Design",
              image: user_friendly_design,
              description: "Our solutions are designed with your team in mind. With an intuitive interface and easy-to-use features."
            },
            {
              title: "Enterprise Resource Planning",
              image: enterprise_resource_planning,
              description: "Manage and automate key business processes with our powerful ERP software."
            },
            {
              title: "Customer Relationship Management",
              image: customer_relationship_management,
              description: "Build better customer relationships and streamline your sales processes."
            },
            {
              title: "Accounting Software",
              image: accounting_software,
              description: "Simplify financial management with our robust accounting software."
            },
            {
              title: "Project Management Tools",
              image: project_management_tools,
              description: "Stay on top of deadlines, assign tasks, and manage resources efficiently."
            },
            {
              title: "Affordable Pricing",
              image: affordable_pricing,
              description: "High-quality software doesn't have to come with a high price tag."
            },
            {
              title: "Custom Software Development",
              image: custom_software_development,
              description: "We specialize in developing custom software tailored to your business requirements."
            },
            {
              title: "Support and Training",
              image: support_and_training,
              description: "We provide ongoing support and training to ensure you're getting the most out of our products."
            },
            {
              title: "Future-Proof Solutions",
              image: future_proof_solutions,
              description: "Our solutions are built to grow with your business, adapting to future needs."
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Elevate Your Brand with PCL Infotech's Software Product Development Services
          </h2>
          <p className="text-lg mb-12">
            At PCL Infotech, we believe that great design is about more than just looks—it's about creating a meaningful connection with your audience.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8 items-center justify-items-center">
            {[
              github_logo,
              angular_logo,
              react_logo,
              mongo_logo,
              nodejs_logo,
              php_logo,
              vs_logo,
              linux_logo,
              msa,
              django
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center"
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-[#004aad] text-white py-2 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Send Message
          </button>
          {isSubmitted && (
            <p className="text-green-600 text-center">Thank you! Your form has been submitted.</p>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default SoftwareProducts;