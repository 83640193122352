import React, { useState } from 'react';

import web_cms_img_2 from './Assets/CMS Website Development/Social-Media-Success-Toolkit-CmsWebsite.webp';
import web_cms_img_4 from './Assets/CMS Website Development/CMS-SEO-Icon-Collection-CmsWebsite.webp';
import user_friendly_management from './Assets/CMS Website Development/User-Friendly-Management-CmsWebsite.webp';
import custom_tailored_solutions from './Assets/CMS Website Development/Custom-Tailored-Solutions-CmsWebsite.webp';
import scalable_and_flexible from './Assets/CMS Website Development/Scalable-And-Flexible-Platforms-CmsWebsite.webp';
import robust_security_feature from './Assets/CMS Website Development/Robust-Security-Features-CmsWebsite.webp';
import integration from './Assets/CMS Website Development/Integration-With-Third-Party-Tools-CmsWebsite.webp';
import ongoing_support_and_training from './Assets/CMS Website Development/Consistent-Branding-CmsWebsite.webp';
import canva_logo from './Assets/CMS Website Development/Canva-CmsWebsite.webp';
import hubspot_logo from './Assets/CMS Website Development/Hubspot-CmsWebsite.webp';
import wix_logo from './Assets/CMS Website Development/WixStudio-CmsWebsite.webp';
import shopify_logo from './Assets/CMS Website Development/Shopify-CmsWebsite.webp';
import wordpress_logo from './Assets/CMS Website Development/WordPress-CmsWebsite.webp';
import webflow_logo from './Assets/CMS Website Development/Webflow-CmsWebsite.webp';

const CMSWebsiteDevelopment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            CMS website development
          </h1>
          <p className="text-lg max-w-xl mb-8">
            Managing and updating your website shouldn't be a hassle. At PCL Infotech, we specialize in CMS (Content Management System) website development, crafting sites that are not only powerful but also incredibly easy to manage.
          </p>
          <button
            onClick={openModal}
            className="w-64 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my CMS website
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_cms_img_2}
            alt="CMS website development"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_cms_img_4}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            Whether you're looking to build a simple blog, a corporate website, or a complex content platform, our CMS solutions are designed to be flexible, scalable, and tailored precisely to your business needs.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's CMS Website Development
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build a platform that not only meets your current needs but also supports your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your CMS Website Development Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "User-Friendly Management",
              image: user_friendly_management,
              description: `Our CMS website solutions empower you to manage your content effortlessly, even without technical expertise. Easily update text, images, and multimedia, and manage your site’s structure with an intuitive interface that simplifies content management using Content management software.`
            },
            {
              title: "Custom-Tailored Solutions",
              image: custom_tailored_solutions,
              description: `We don’t believe in one-size-fits-all. Our Content Management System solutions are customized to meet your specific needs, whether you're launching a blog, a corporate site, or a large-scale content platform. We also offer WordPress development to ensure your site is fully aligned with your goals and workflows.`
            },
            {
              title: "Scalable and Flexible Platforms",
              image: scalable_and_flexible,
              description: `Your website should expand effortlessly alongside your business. Our Content Management System solutions are designed with scalability in mind, enabling you to add new features, pages, and functionalities as your needs evolve without starting from scratch.`
            },
            {
              title: "Robust Security Features",
              image: robust_security_feature,
              description: "Security is a top priority in all our CMS projects. We implement strong security measures to protect your website."
            },
            {
              title: "Integration with Third-Party Tools",
              image: integration,
              description: `Enhance your website’s functionality by integrating it with third-party tools and services, such as CRM systems, marketing automation platforms, and social media channels. Our CMS website solutions are designed to seamlessly integrate with the tools you already use, including our WordPress development services.`
            },
            {
              title: "Consistent Branding",
              image: ongoing_support_and_training,
              description: `We offer continuous support and training to ensure you and your team are comfortable managing your new Content Management System.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Technology Stack We Consider for Outsourcing Web Design
          </h2>
          <p className="text-lg mb-12">
            Our team of certified web developers is competent to work on different technologies and platforms that leverage our clients with the flexibility to opt from choices and get consultation accordingly.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-items-center">
            {[
              canva_logo,
              hubspot_logo,
              wix_logo,
              shopify_logo,
              wordpress_logo,
              webflow_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}

    </div>
  );
};

export default CMSWebsiteDevelopment;