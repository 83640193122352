import React, { useState } from 'react';
import tp_insp_img_1 from './Assets/Training and placement/Internships/Internships-2.webp';
import tp_insp_img_2 from './Assets/tp_insp_img_2.png';
import stack_develop from './Assets/stack_develop.png';
import ui_ux_desinger_1 from './Assets/ui_ux_desinger_1.png';
import hr_intership from './Assets/hr_intership.png';
import data from './Assets/data.png';
import digital_market from './Assets/digital_market.png';
import Modal from 'react-modal';

const Internship = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="h-auto">
      {/* Intro Section */}
      <div className="flex justify-center items-center p-5">
        <img src={tp_insp_img_1} alt="Internship Program" className="w-full h-auto max-w-[1650px]" />
      </div>
      <div className="text-center mt-5">
        <h1 className="text-5xl text-[#004aad] font-semibold">Internships</h1>
      </div>

      {/* Internship Overview Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-20 p-5 mt-10">
        <img src={tp_insp_img_2} alt="Internship Overview" className="w-full h-auto max-w-[550px]" />
        <div className="max-w-[950px]">
          <h4 className="mt-8 text-lg font-normal text-gray-700">
            Kick-start your career with our comprehensive internship programs. At PCL Infotech, we offer real-world experience and valuable learning opportunities in a supportive and innovative environment. Our internships provide hands-on learning opportunities, enabling individuals to apply theoretical knowledge in a real-world work environment.
          </h4>
          <h3 className="mt-5 text-xl font-semibold text-[#004aad]">Hands-On Learning</h3>
          <p className="text-lg">Gain practical experience by working on live projects that challenge and develop your skills.</p>
          <h3 className="mt-5 text-xl font-semibold text-[#004aad]">Mentorship</h3>
          <p className="text-lg">Receive guidance and feedback from experienced professionals who are dedicated to your growth.</p>
          <h3 className="mt-5 text-xl font-semibold text-[#004aad]">Skill Development</h3>
          <p className="text-lg">Build a strong foundation in your field with exposure to the latest technologies and industry practices.</p>
          <h3 className="mt-5 text-xl font-semibold text-[#004aad]">Career Preparation</h3>
          <p className="text-lg">Prepare for your future career in a dynamic, collaborative, and innovative environment.</p>
        </div>
      </div>

      {/* Our Offerings Section */}
      <div className="text-center mt-10">
        <h2 className="text-2xl font-bold text-[#004aad]">Our Offerings</h2>
        <p className="mt-2 text-lg">One of the industry's leading Project Based Career Programs offered by PCL INFOTECH, promising Placement Guidance upon course completion.</p>
      </div>

      {/* Cards Section */}
      <div className="flex flex-col items-center mt-10">
        <div className="flex flex-wrap justify-center gap-6 p-5">
          {/* Card 1 */}
          <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg w-[350px] p-4">
            <img src={stack_develop} alt="Full Stack Developer" className="w-40 h-auto" />
            <button onClick={openModal} className="flex items-center justify-between bg-[#004aad] text-white rounded py-2 px-4 mt-4 transition duration-300 hover:bg-[#00308a]">
              View Course
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-4 h-4">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
            <h3 className="mt-4 text-lg font-semibold">FULL STACK DEVELOPER</h3>
          </div>

          {/* Modal for Viewing Course */}
          <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content">
            <div className="web-contact-form p-5">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">First Name</label>
                  <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required className="mt-1 block w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Last Name</label>
                  <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required className="mt-1 block w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required className="mt-1 block w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                  <input type="text" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} className="mt-1 block w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea name="message" placeholder="Write your message.." value={formData.message} onChange={handleChange} required className="mt-1 block w-full p-2 border border-gray-300 rounded"></textarea>
                </div>
                <button type="submit" className="w-full bg-[#004aad] text-white rounded py-2 transition duration-300 hover:bg-[#00308a]">Send Message</button>
                {isSubmitted && <p className="mt-3 text-green-600">Thank you! Your form has been submitted.</p>}
              </form>
            </div>
          </Modal>

          {/* Card 2 */}
          <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg w-[350px] p-4">
            <img src={ui_ux_desinger_1} alt="UI UX Designer" className="w-40 h-auto" />
            <button onClick={openModal} className="flex items-center justify-between bg-[#004aad] text-white rounded py-2 px-4 mt-4 transition duration-300 hover:bg-[#00308a]">
              View Course
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-4 h-4">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
            <h3 className="mt-4 text-lg font-semibold">UI UX DESIGNER</h3>
          </div>

          {/* Card 3 */}
          <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg w-[350px] p-4">
            <img src={hr_intership} alt="HR Internship" className="w-40 h-auto" />
            <button onClick={openModal} className="flex items-center justify-between bg-[#004aad] text-white rounded py-2 px-4 mt-4 transition duration-300 hover:bg-[#00308a]">
              View Course
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-4 h-4">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
            <h3 className="mt-4 text-lg font-semibold">HR INTERNSHIP</h3>
          </div>
        </div>

        {/* Additional Internship Offerings */}
        <div className="tp-insp-subcard-2 flex flex-row justify-center gap-6">
          <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg w-[350px] p-4">
            <img src={data} alt="Data Analyst" className="w-40 h-auto" />
            <button onClick={openModal} className="flex items-center justify-between bg-[#004aad] text-white rounded py-2 px-4 mt-4 transition duration-300 hover:bg-[#00308a]">
              View Course
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-4 h-4">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
            <h3 className="mt-4 text-lg font-semibold">DATA ANALYST</h3>
          </div>
          <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg w-[350px] p-4">
            <img src={digital_market} alt="Digital Marketing" className="w-40 h-auto" />
            <button onClick={openModal} className="flex items-center justify-between bg-[#004aad] text-white rounded py-2 px-4 mt-4 transition duration-300 hover:bg-[#00308a]">
              View Course
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-4 h-4">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
            <h3 className="mt-4 text-lg font-semibold">DIGITAL MARKETING</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Internship;