import React, { useState } from 'react';
import './CSS/ProductsDesign.css';
import dtg_pd_img_2 from './Assets/Product design/Dreamscape-Desk-Packaging-design.webp';
import concept from './Assets/Product design/Concept-Development-Packaging-design.webp';
import user_centered_design from './Assets/Product design/User-Centered-Design-Packaging-design.webp';
import wireframing_prototyping from './Assets/Product design/Wireframing-And-Prototyping-Packaging-design.webp';
import iterative_design_testing from './Assets/Product design/Iterative-Design-And-Testing-Packaging-design.webp';
import tailored_solutions from './Assets/Product design/Tailored-Solutions-Packaging-design.webp';
import focus_on_usability from './Assets/Product design/Focus on Usability-Packaging-design.webp';
import collaborative_process from './Assets/Product design/Collaborative-Process-Packaging-design.webp';
import expert_team from './Assets/Product design/Expert-Team-Packaging-design.webp';
import figma_logo from './Assets/Product design/Figma-Packaging-design.webp';
import canva_logo from './Assets/Product design/Canva-Packaging-design.webp';
import balsamiq_logo from './Assets/Product design/Balsamiq-Packaging-design.webp';
import xd_logo from './Assets/Product design/Adobe-Illustrator-Packaging-design.webp';
import ai_logo from './Assets/Product design/Adobe-Illustrator-Packaging-design.webp';
import photoshop_logo from './Assets/Product design/Photoshop-Packaging-design.webp';
import sketch_logo from './Assets/Product design/Sketch-Packaging-design.webp';
import zeplin_logo from './Assets/Product design/Zeppelin-Packaging-design.webp';
import fusion_logo from './Assets/Product design/Autodesk-Fusion-Packaging-design.webp';
import Modal from 'react-modal';


const ProductsDesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Product Design Services
          </h1>
          <p className="text-lg max-w-xl mb-8">
            At PCL Infotech, we offer comprehensive product design services that turn your ideas into fully functional, user-centered digital products. From concept to completion, we work closely with you to design IT solutions that not only meet your business objectives but also deliver an exceptional user experience.
          </p>
          <button
            onClick={openModal}
            className="w-74 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Product Design Services
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={dtg_pd_img_2}
            alt="Product design showcase"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Ideas with PCL Infotech's Product Design Services
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you create products that not only meet your current needs but also support your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Our Product Design Services Include
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Concept Development",
              image: concept,
              description: "We collaborate with you to transform your vision into a well-defined concept through research, brainstorming, and strategic planning."
            },
            {
              title: "User-Centered Design",
              image: user_centered_design,
              description: "Our design process prioritizes user experience through in-depth research and intuitive solutions."
            },
            {
              title: "Wireframing & Prototyping",
              image: wireframing_prototyping,
              description: "We develop interactive prototypes that provide clear visual representation of product layout and functionality."
            },
            {
              title: "Iterative Design & Testing",
              image: iterative_design_testing,
              description: "Through iterative design, we test and refine the product at every stage for optimal quality."
            },
            {
              title: "Tailored Solutions",
              image: tailored_solutions,
              description: "We create bespoke designs that align perfectly with your brand identity and business needs."
            },
            {
              title: "Focus on Usability",
              image: focus_on_usability,
              description: "We ensure your product is intuitive, easy to navigate, and delivers maximum value."
            },
            {
              title: "Collaborative Process",
              image: collaborative_process,
              description: "We work closely with you throughout the design process, integrating feedback at every stage."
            },
            {
              title: "Expert Team",
              image: expert_team,
              description: "Our experienced team brings deep expertise in creating successful digital products."
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Tools We Use to Create Amazing Designs
          </h2>
          <p className="text-lg mb-12">
            We leverage industry-leading design tools to create exceptional user experiences and stunning visual designs.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8 items-center justify-items-center">
            {[
              figma_logo,
              canva_logo,
              balsamiq_logo,
              xd_logo,
              ai_logo,
              photoshop_logo,
              sketch_logo,
              zeplin_logo,
              fusion_logo
            ].map((src, index) => (
              <img key={index} src={src} alt={`Design Tool ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center"
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border rounded h-32"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-[#004aad] text-white py-2 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Send Message
          </button>
          {isSubmitted && (
            <p className="text-green-600 text-center">Thank you! Your form has been submitted.</p>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default ProductsDesign;