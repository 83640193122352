import React from 'react';
import Innovative_Technology_Solutions_IT_service from '../Assets/home/Innovative-Technology-Solutions-IT-service.webp'
import customized_it_strategies from '../Assets/customized_it_strategies.png'
import scalable_growth_solutions from '../Assets/home/Scalable-Growth-Solutions-IT-Service.webp'
import strategic_partnership from '../Assets/home/Strategic-Partnership-IT-Service.webp'
import robust_security_measures from '../Assets/home/Strategic-Partnership-IT-Service.webp'
import commitment_to_excellence from '../Assets/home/Commitment-To-Excellence-IT-Service.webp'
import agile_and_adaptive from '../Assets/home/Agile-And-Adaptive-IT-Service.webp'
import digital_innovative from '../Assets/digital_innovative.png'

const ServiceCard = ({ image, title, description }) => (
  <div className="group flex flex-col items-center justify-start p-6 w-full h-full min-h-[400px] rounded-xl bg-white shadow-md hover:shadow-xl transition-all duration-300 ease-in-out border border-gray-100 hover:border-blue-500">
    <div className="w-16 h-16 md:w-20 md:h-20 mb-6 transition-transform duration-300 group-hover:scale-110">
      <img
        src={image}
        alt={title}
        className="w-full h-full object-contain"
      />
    </div>
    <h3 className="text-lg md:text-xl font-bold text-blue-600 text-center mb-4 group-hover:text-blue-700">
      {title}
    </h3>
    <p className="text-sm md:text-base text-gray-600 text-center leading-relaxed">
      {description}
    </p>
  </div>
);

const Services = () => {
  const services = [
    {
      image: Innovative_Technology_Solutions_IT_service,
      title: "Innovative Technology Solutions",
      description: "Stay ahead in a rapidly evolving digital world with our cutting-edge IT services. We leverage the latest technologies to ensure your business remains at the forefront of innovation and efficiency."
    },
    {
      image: customized_it_strategies,
      title: "Customized IT Strategies",
      description: "We recognize that your business is unique, and so are your challenges. That’s why we deliver tailored IT solutions that align perfectly with your specific needs and objectives, ensuring maximum impact."
    },
    {
      image: scalable_growth_solutions,
      title: "Scalable Growth Solutions",
      description: "As your business grows, so do your IT needs. Our solutions are designed to scale with your business, providing the flexibility and adaptability required to thrive in a dynamic market. Our IT consultancy services ensure that your systems evolve with your growing business"
    },
    {
      image: strategic_partnership,
      title: "Strategic Partnership",
      description: "We don’t just provide services; we build partnerships. We work closely with you to understand your business goals and craft IT strategies that support your long-term vision, offering expert IT consulting throughout the process."
    },
    {
      image: robust_security_measures,
      title: "Robust Security Measures",
      description: "In an age where cybersecurity is paramount, we prioritize the safety and security of your data. Our comprehensive security solutions protect your business from threats, giving you peace of mind with our IT service management expertise"
    },
    {
      image: commitment_to_excellence,
      title: "Commitment To Excellence",
      description: "At PCL Infotech, we don’t just meet expectations—we exceed them. Our unwavering commitment to quality, innovation, and customer satisfaction sets us apart as a trusted Software Development Company."
    },
    {
      image: agile_and_adaptive,
      title: "Agile And Adaptive",
      description: "In today’s fast-paced environment, agility is key. We pride ourselves on our ability to quickly adapt to changes and deliver solutions that keep your business responsive and resilient. Our IT solutions offer the flexibility your business needs to remain competitive."
    },
    {
      image: digital_innovative,
      title: "Digital Innovation Excellence",
      description: "Our IT department drives digital transformation through cutting-edge solutions and forward-thinking strategies. We integrate emerging technologies and best practices to enhance operational efficiency, ensuring your organization stays ahead in an evolving digital landscape."
    }
  ];

  return (
    <section className="w-full bg-gray-50 py-12 md:py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12 md:mb-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold">
            <span className="text-gray-800">What sets </span>
            <span className="text-blue-600">PCL Infotech </span>
            <span className="text-gray-800">Apart</span>
          </h2>
          <div className="mt-4 w-24 h-1 bg-blue-600 mx-auto rounded-full"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              image={service.image}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;