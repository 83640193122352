import React, { useState } from 'react';
import hs_domain_img_2 from './Assets/Domain Registration/Domain-Registration.webp';
import domain_name_consultation from './Assets/Domain Registration/Domain-Name-Consultation-Domain-Registration.webp';
import domain_extensions from './Assets/Domain Registration/Domain-Extensions-Domain-Registration.webp';
import domain_transfer_services from './Assets/Domain Registration/Domain-Transfer-Services-Domain-Registration.webp';
import domain_privacy_protection from './Assets/Domain Registration/Domain-Privacy-Protection-Domain-Registration.webp';
import domain_renewal_management from './Assets/Domain Registration/domain_renewal_management.f36e5e0d374448ae4248.webp';
import subdomains_and_customization from './Assets/Domain Registration/Subdomains-and-customization-Domain-Registration.webp';


const Domain = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      setIsModalOpen(false);
      setTimeout(() => setIsSubmitted(false), 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const services = [
    {
      title: "Domain Name Consultation",
      description: "We guide you through the process of selecting the best domain name, considering factors like brand identity, keyword relevance, and marketability.",
      image: domain_name_consultation
    },
    {
      title: "Domain Extensions",
      description: "Whether you're looking for a classic '.com' or specialized options like '.tech,' '.shop,' or '.org,' we assist you in securing the ideal extensions to enhance your online visibility.",
      image: domain_extensions
    },
    {
      title: "Domain Transfer Services",
      description: "If you already own a domain and want to transfer it, we handle the entire process to ensure a seamless transition with no downtime.",
      image: domain_transfer_services
    },
    {
      title: "Domain Privacy Protection",
      description: "Protect your personal information from being publicly accessible in domain registries by adding privacy protection, keeping your contact details safe from spammers and unwanted solicitation.",
      image: domain_privacy_protection
    },
    {
      title: "Domain Renewal Management",
      description: "Avoid the risk of losing your domain due to expiration. We handle domain renewals, ensuring your online presence is never interrupted.",
      image: domain_renewal_management
    },
    {
      title: "Branding & Call-to-Actions",
      description: "Need subdomains for different areas of your business? We assist in setting up and managing subdomains to give your website structure and flexibility.",
      image: subdomains_and_customization
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Header Section */}
      <div className="flex flex-col lg:flex-row justify-center items-center gap-20 bg-blue-600 px-8 py-10">
        <div className="space-y-8 max-w-2xl">
          <h1 className="text-4xl text-white font-bold">Domain Registration</h1>
          <p className="text-lg text-white">
            Your domain name is the first step toward establishing a strong online identity.
            It's more than just your website's address—it's the cornerstone of your brand's
            online presence. At PCL Infotech, we offer comprehensive domain registration
            services that help you secure a domain name that reflects your brand, resonates
            with your target audience, and aligns with your business goals.
          </p>
          <button
            onClick={() => window.open("http://host.pclinfotech.com/", "_blank")}
            className="px-6 py-2 bg-white text-black font-semibold rounded-md shadow-lg hover:bg-gray-100 transition-colors"
          >
            Click here
          </button>
        </div>
        <div className="w-full max-w-xl">
          <img
            src={hs_domain_img_2}
            alt="Domain registration illustration"
            className="w-full h-auto my-10"
          />
        </div>
      </div>

      {/* Services Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center text-blue-700 mb-16">
          Why Choose PCL Infotech
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-8 rounded-[45px] shadow-lg hover:shadow-xl transition-shadow cursor-pointer space-y-6"
            >
              <img
                src={service.image}
                alt={service.title}
                className="w-24 h-auto object-contain"
              />
              <h3 className="text-xl font-semibold text-center">{service.title}</h3>
              <p className="text-center text-lg text-gray-600 max-w-[300px]">{service.description}</p>
            </div>
          ))}
        </div>

        {/* Contact Button */}
        <div className="text-center mt-10 mb-1">
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-8 py-3 bg-blue-700 text-white rounded-lg shadow-lg hover:bg-blue-800 transition-colors"
          >
            CONTACT US
          </button>
        </div>
      </div>
    </div>
  );
};

export default Domain;