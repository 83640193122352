import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Menu, X } from 'lucide-react';
import logo from '../Assets/logo.png';
import web_development_icon from '../Assets/web_development_icon.png';
import mobile_app_development from '../Assets/mobile_app_development.png';
import web_design_icon from '../Assets/web_design_icon.png';
import ecommerce_website_development from '../Assets/ecommerce_website_development.png';
import email_templates from '../Assets/email_templates.png';
import cms_website from '../Assets/cms_website.png';
import open_source_development from '../Assets/open_source_development.png';
import payment_gateway_integration from '../Assets/payment_gateway_integration.png';
import content_writing from '../Assets/content_writing.png';
import search_engine_marketing_SEM from '../Assets/search_engine_marketing_SEM.png';
import social_media_marketing_SMM from '../Assets/social_media_marketing_SMM.png';
import social_media_marketing from '../Assets/social_media_marketing.png';
import domain_registration from '../Assets/domain_registration.png';
import vps_hosting from '../Assets/vps_hosting.png';
import wordpress_hosting from '../Assets/wordpress_hosting.png';
import cloud_server from '../Assets/cloud_server.png';
import digital_marketing from '../Assets/digital_marketing.png';
import branding from '../Assets/branding.png';
import commerce from '../Assets/commerce.png';
import training_placement from '../Assets/training_placement.png';
import internships from '../Assets/internships.png';
import communication from '../Assets/communication.png';
import randd from '../Assets/randd.png';
import software_products from '../Assets/software_products.png';
import product_design from '../Assets/product_design.png';

const navigationData = {
    ecommerce: {
        title: "E- Commerce",
        items: [
            {
                title: "PCL- Mart",
                path: "/e-commerce",
                icon: [commerce]
            }
        ]
    },
    services: {
        webDevelopment: {
            title: "Web Development",
            icon: [web_development_icon],
            items: [
                {
                    title: "Web Application",
                    path: "/web-development",
                    icon: [web_development_icon]
                },
                {
                    title: "Mobile App Development",
                    path: "/mobile-app-development",
                    icon: [mobile_app_development]
                },
                {
                    title: "Web Design",
                    path: "/web-design",
                    icon: [web_design_icon]
                },
                {
                    title: "eCommerce Website",
                    path: "/ecommerce-website-development",
                    icon: [ecommerce_website_development]
                },
                {
                    title: "Email Templates",
                    path: "/email-templates",
                    icon: [email_templates]
                },
                {
                    title: "CMS Website",
                    path: "/cms-website-development",
                    icon: [cms_website]
                },
                {
                    title: "Open Source Development",
                    path: "/open-source-development",
                    icon: [open_source_development]
                },
                {
                    title: "Payment Gateway Integration",
                    path: "/payment-gateway-integration",
                    icon: [payment_gateway_integration]
                }
            ]
        },
        digitalMarketing: {
            title: "Digital Marketing",
            icon: [digital_marketing],
            items: [
                {
                    title: "Advertising",
                    path: "/advertising",
                    icon: [search_engine_marketing_SEM]
                },
                {
                    title: "Social Media Marketing (SMM)",
                    path: "/social-media-marketing",
                    icon: [social_media_marketing_SMM]
                },
                {
                    title: "Search Engine Optimization (SEO)",
                    path: "/search-engine-optimization",
                    icon: [social_media_marketing]
                },
                {
                    title: "Content Writing",
                    path: "/content-writing",
                    icon: [content_writing]
                },
                {
                    title: "Branding",
                    path: "/branding",
                    icon: [branding]
                }
            ]
        },
        softwareProducts: {
            title: "Software Products",
            path: "/software-products",
            icon: [software_products]
        },
        productDesigns: {
            title: "Product Designs",
            path: "/product-designs",
            icon: [product_design]
        }
    },
    hosting: {
        title: "Hosting / Server",
        items: [
            {
                title: "Domain Registration",
                path: "/domain-registration",
                icon: [domain_registration]
            },
            {
                title: "VPS Hosting",
                path: "/vps-hosting",
                icon: [vps_hosting]
            },
            {
                title: "WordPress Hosting",
                path: "/wordpress-hosting",
                icon: [wordpress_hosting]
            },
            {
                title: "Cloud Server",
                path: "/cloud-server",
                icon: [cloud_server]
            }
        ]
    },
    training: {
        title: "Training and Placement",
        items: [
            {
                title: "Courses",
                path: "/Training-and-placements-courses",
                icon: [training_placement]
            },
            {
                title: "Internships",
                path: "/Training-and-placements-internships",
                icon: [internships]
            }
        ]
    },
    communication: {
        title: "Communication",
        items: [
            {
                title: "Management",
                path: "/data-analytics",
                icon: [communication]
            }
        ]
    },
    randd: {
        title: "R & D",
        items: [
            {
                title: "Technology",
                path: "/R&D",
                icon: [randd]
            }
        ]
    }
};

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");
    const [activeSubmenu, setActiveSubmenu] = useState("");
    const [isMediumScreen] = useState(false);

    const handleMenuClick = (menu) => {
        if (activeMenu === menu) {
            setActiveMenu("");
        } else {
            setActiveMenu(menu);
        }
    };

    const handleSubmenuClick = (submenu) => {
        if (activeSubmenu === submenu) {
            setActiveSubmenu("");
        } else {
            setActiveSubmenu(submenu);
        }
    };

    const MenuLink = ({ to, onClick, icon, children }) => (
        <Link
            to={to}
            onClick={() => {
                onClick?.();
                setIsOpen(false);
                setActiveMenu("");
                setActiveSubmenu("");
            }}
            className="flex items-center px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-blue-700 transition-colors duration-300"
        >
            {icon && <img src={icon} alt="" className="w-5 h-5 mr-2" />}
            {children}
        </Link>
    );

    return (
        <nav className="sticky top-0 z-50 bg-white shadow-md">
            <div className="max-w-8xl mx-auto px-2 sm:px-3 lg:px-4">
                <div className="flex justify-between items-center h-16">
                    {/* Logo */}
                    <Link to="/" className="flex-shrink-0">
                        <img src={logo} alt="Logo" className="h-8 w-auto" />
                    </Link>

                    {/* Desktop Navigation */}
                    <div className={`hidden ${isMediumScreen ? 'md:flex' : 'lg:flex'} lg:items-center lg:space-x-1`}>
                        <MenuLink to="/">Home</MenuLink>

                        {/* E-Commerce Dropdown */}
                        <div className="relative group">
                            <button className="flex items-center px-3 py-2 text-sm text-gray-700 hover:text-blue-600">
                                <span>{navigationData.ecommerce.title}</span>
                                <ChevronDown className="ml-1 h-4 w-4" />
                            </button>
                            <div className="absolute left-0 hidden group-hover:block w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 py-2">
                                {navigationData.ecommerce.items.map((item) => (
                                    <MenuLink key={item.path} to={item.path} icon={item.icon}>
                                        {item.title}
                                    </MenuLink>
                                ))}
                            </div>
                        </div>

                        {/* Services Dropdown */}
                        <div className="relative group">
                            <button className="flex items-center px-3 py-2 text-sm text-gray-700 hover:text-blue-600">
                                <span>Services</span>
                                <ChevronDown className="ml-1 h-4 w-4" />
                            </button>
                            <div className="absolute left-0 hidden group-hover:block w-72 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                {Object.entries(navigationData.services).map(([key, section]) => (
                                    <div key={key} className="relative group/sub">
                                        {section.items ? (
                                            <>
                                                <div className="flex items-center justify-between px-3 py-3 text-sm text-gray-700 hover:bg-gray-50">
                                                    <div className="flex items-center">
                                                        <img src={section.icon} alt="" className="w-5 h-5 mr-2" />
                                                        <span>{section.title}</span>
                                                    </div>
                                                    <ChevronDown className="h-4 w-4" />
                                                </div>
                                                <div className="absolute left-full top-0 hidden group-hover/sub:block w-64 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 -ml-1">
                                                    {section.items.map((item) => (
                                                        <MenuLink key={item.path} to={item.path} icon={item.icon}>
                                                            {item.title}
                                                        </MenuLink>
                                                    ))}
                                                </div>
                                            </>
                                        ) : (
                                            <MenuLink to={section.path} icon={section.icon}>
                                                {section.title}
                                            </MenuLink>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Other Dropdowns */}
                        {['hosting', 'training', 'communication'].map((section) => (
                            <div key={section} className="relative group">
                                <button className="flex items-center px-3 py-2 text-sm text-gray-700 hover:text-blue-600">
                                    <span>{navigationData[section].title}</span>
                                    <ChevronDown className="ml-1 h-4 w-4" />
                                </button>
                                <div className="absolute left-0 hidden group-hover:block w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 py-2">
                                    {navigationData[section].items.map((item) => (
                                        <MenuLink key={item.path} to={item.path} icon={item.icon}>
                                            {item.title}
                                        </MenuLink>
                                    ))}
                                </div>
                            </div>
                        ))}

                        {/* Other Dropdowns */}
                        {['randd'].map((section) => (
                            <div key={section} className="relative group">
                                <button className="flex items-center px-3 py-2 text-sm text-gray-700 hover:text-blue-600">
                                    <span>{navigationData[section].title}</span>
                                    <ChevronDown className="ml-1 h-4 w-4" />
                                </button>
                                <div className="absolute left-0 hidden group-hover:block w-36 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 py-2">
                                    {navigationData[section].items.map((item) => (
                                        <MenuLink key={item.path} to={item.path} icon={item.icon}>
                                            {item.title}
                                        </MenuLink>
                                    ))}
                                </div>
                            </div>
                        ))}

                        <MenuLink to="/contact-info">Contact Us</MenuLink>
                    </div>

                    {/* Mobile menu button */}
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className={`${isMediumScreen ? 'md:hidden' : 'lg:hidden'} p-2 rounded-md text-gray-700 hover:text-blue-600 focus:outline-none`}
                    >
                        {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                    </button>
                </div>
            </div>

            {/* Mobile Navigation */}
            {isOpen && (
                <div className={`${isMediumScreen ? 'md:hidden' : 'lg:hidden'} bg-white border-t`}>
                    <div className="px-3 py-2 space-y-1 max-h-[calc(100vh-4rem)] overflow-y-auto">
                        <MenuLink to="/">Home</MenuLink>

                        {/* Mobile Services Menu */}
                        <div className="space-y-1">
                            <button
                                onClick={() => handleMenuClick('services')}
                                className="w-full flex items-center justify-between px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                            >
                                <span>Services</span>
                                <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${activeMenu === 'services' ? 'rotate-180' : ''}`} />
                            </button>

                            {activeMenu === 'services' && (
                                <div className="pl-3 space-y-1">
                                    {Object.entries(navigationData.services).map(([key, section]) => (
                                        <div key={key}>
                                            {section.items ? (
                                                <>
                                                    <button
                                                        onClick={() => handleSubmenuClick(key)}
                                                        className="w-full flex items-center justify-between px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                                                    >
                                                        <div className="flex items-center">
                                                            <img src={section.icon} alt="" className="w-5 h-5 mr-2" />
                                                            <span>{section.title}</span>
                                                        </div>
                                                        <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${activeSubmenu === key ? 'rotate-180' : ''}`} />
                                                    </button>

                                                    {activeSubmenu === key && (
                                                        <div className="pl-3 space-y-1">
                                                            {section.items.map((item) => (
                                                                <MenuLink key={item.path} to={item.path} icon={item.icon}>
                                                                    {item.title}
                                                                </MenuLink>
                                                            ))}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <MenuLink to={section.path} icon={section.icon}>
                                                    {section.title}
                                                </MenuLink>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Other Mobile Menus */}
                        {['hosting', 'training', 'communication', 'randd'].map((section) => (
                            <div key={section} className="space-y-1">
                                <button
                                    onClick={() => handleMenuClick(section)}
                                    className="w-full flex items-center justify-between px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                                >
                                    <span>{navigationData[section].title}</span>
                                    <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${activeMenu === section ? 'rotate-180' : ''}`} />
                                </button>

                                {activeMenu === section && (
                                    <div className="pl-3 space-y-1">
                                        {navigationData[section].items.map((item) => (
                                            <MenuLink key={item.path} to={item.path} icon={item.icon}>
                                                {item.title}
                                            </MenuLink>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}

                        <MenuLink to="/contact-info">Contact Us</MenuLink>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;