import React, { useState } from 'react';

import './CSS/EmailTemplates.css';
import web_mail_img_2 from './Assets/E-mail templates/Dynamic-Email-Template-Suite-Email-templates.webp';
import web_mail_img_3 from './Assets/E-mail templates/ConversaMail-Templates-Email-templates.webp';
import brand_consistency from './Assets/E-mail templates/Brand-Consistency-Email-templates.webp';
import responsive_design from './Assets/E-mail templates/Responsive-Design-Email-templates.webp';
import high_engagement from './Assets/E-mail templates/High-Engagement-Email-templates.webp';
import customizable_and_flexible from './Assets/E-mail templates/Customizable-and-Flexible-Email-templates.webp';
import tested_for_compatibility from './Assets/E-mail templates/Tested-For-Compatibility-Email-templates.webp';
import ongoing from './Assets/E-mail templates/Ongoing-Support-Email-templates.webp';
import zendesk_logo from './Assets/E-mail templates/Zendesk-Email-templates.webp';
import hubspot_logo from './Assets/E-mail templates/Hubspot-Email-templates.webp';
import stripo_logo from './Assets/E-mail templates/Stripo-Email-templates.webp';
import brevo_logo from './Assets/E-mail templates/Brevo-Email-templates.webp';
import constant_logo from './Assets/E-mail templates/Constant-Contact-Email-templates.webp';
import mailchimp_logo from './Assets/E-mail templates/Mailchimp-Email-templates.webp';


const EmailTemplates = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Email templates development
          </h1>
          <p className="text-lg max-w-xl mb-8">
            Email marketing is a powerful tool for reaching and engaging your audience, and the right email design can make all the difference. At PCL Infotech, we specialize in creating professional, responsive email templates that are designed to captivate your recipients and drive results.
          </p>
          <button
            onClick={openModal}
            className="w-74 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my E-mail Template Development
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_mail_img_2}
            alt="A man and woman collaborate on a laptop, crafting and sending emails using various email templates."
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_mail_img_3}
            alt="A man sitting on a chair, engaged with his phone, surrounded by chat bubbles representing conversation."
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            Our templates are not only visually appealing but also optimized to perform flawlessly across all devices and email clients, ensuring your message is delivered effectively every time.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's E-mail Templates Development
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth. Let us be your partner in creating a powerful, user-friendly online store that converts visitors into loyal customers and drives sustained business success.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Email Templates Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Brand Consistency",
              image: brand_consistency,
              description: `We design email templates that perfectly align with your brand’s messaging and visual identity. Every element, from colors to fonts, is carefully chosen to ensure your emails reflect your brand’s personality and values.`
            },
            {
              title: "Responsive Design",
              image: responsive_design,
              description: `With an increasing number of users checking emails on mobile devices, your email marketing templates must look great on every screen. Our templates are fully responsive, adapting seamlessly to different devices and email clients for a consistent user experience.`
            },
            {
              title: "High Engagement",
              image: high_engagement,
              description: `Our email template design is focused on engagement, featuring clear calls to action, eye-catching visuals, and easy-to-read layouts. These designs are crafted to boost open rates, click-through rates, and conversions.`
            },
            {
              title: "Customizable and Flexible",
              image: customizable_and_flexible,
              description: `Our templates are easy to customize, allowing you to tailor each campaign to specific audiences or objectives. Whether you need a promotional email, a newsletter, or an announcement, our templates offer the flexibility to suit any purpose.`
            },
            {
              title: "Tested for Compatibility",
              image: tested_for_compatibility,
              description: `We rigorously test our email templates across various email clients and platforms to ensure optimal performance. This includes compatibility testing with major services like Gmail, Outlook, and Apple Mail.`
            },
            {
              title: "Ongoing Support",
              image: ongoing,
              description: `We don’t just deliver the template and walk away. We offer ongoing support to ensure your email design runs smoothly, providing advice on best practices, optimization tips, and necessary updates.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Elevate Your Brand with PCL Infotech's e-Commerce Web Development Services
          </h2>
          <p className="text-lg mb-12">
            At PCL Infotech, we believe that great design is about more than just looks—it's about creating a meaningful connection with your audience.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-items-center">
            {[
              zendesk_logo,
              hubspot_logo,
              stripo_logo,
              brevo_logo,
              constant_logo,
              mailchimp_logo
            ].map((logo, index) => (
              <img key={index} src={logo} alt={`Technology ${index + 1}`} className="w-20 md:w-32 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}


    </div>
  );
};

export default EmailTemplates;