import React, { useState } from 'react';

import web_opn_img_2 from './Assets/Open source development/DevSphere-open-source-software.webp';
import web_opn_img_3 from './Assets/Open source development/Collaborative-Robotics-Suite-open-source-software.webp';
import custom_solutions from './Assets/Open source development/Custom-Solutions-open-source-software.webp';
import cost_effective_development from './Assets/Open source development/Cost-Effective-Development-open-source-software.webp';
import community_driven_innovation from './Assets/Open source development/Community-Driven-Innovation-open-source-software.webp';
import scalability_and_flexibility from './Assets/Open source development/Scalability-And-Flexibility-open-source-software.webp';
import security_and_stability from './Assets/Open source development/Security-And-Stability-open-source-software.webp';
import seamless_integrations_1 from './Assets/Open source development/Social-Media-Growth-Blueprint-open-source-software.webp';
import maintenance from './Assets/Open source development/Seamless-Integrations-open-source-software.webp';
import html_logo from './Assets/Open source development/HTML-Programming-Language-open-source-software.webp';
import css_logo from './Assets/Open source development/CSS-Programming-Language-open-source-software.webp';
import javascript_logo from './Assets/Open source development/JavaScript-Programming-Language-open-source-software.webp';
import react_logo from './Assets/Open source development/React-Programming-Language-open-source-software.webp';
import angular_logo from './Assets/Open source development/Angular-Programming-Language-open-source-software.webp';
import next_logo from './Assets/Open source development/Next.JS-Programming-Language-open-source-software.webp';
import express_logo from './Assets/Open source development/Express.js -Programming-Language-open-source-software.webp';
import nodejs_logo from './Assets/Open source development/Node.js -Programming-Language-open-source-software.webp';
import mongo_logo from './Assets/Open source development/MangoDB -Programming-Language-open-source-software.webp';
import php_logo from './Assets/Open source development/PHP-Programming-Language-open-source-software.webp';
import mysql_logo from './Assets/Open source development/MySQL-Programming-Language-open-source-software.webp';
import postsql_logo from './Assets/Open source development/PostgreSQL-Programming-Language-open-source-software.webp';
import vs from "./Assets/Open source development/Visual-Studio-Code-open-source-software.webp";
import linux from './Assets/Open source development/Linux-open-source-software.webp';
import github from './Assets/Open source development/Github-open-source-software.webp';

const OpenSourceDevelopment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:4000/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      setIsSubmitted(true);
      closeModal();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 p-4">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-[#004aad] mb-6">
            Open source development
          </h1>
          <p className="text-lg max-w-xl mb-8">
            Open-source software platforms offer a flexible, scalable, and cost-effective foundation for building customized digital solutions. At PCL Infotech, we leverage the power of open-source development to deliver robust and innovative websites, applications, and e-commerce platforms.
          </p>
          <button
            onClick={openModal}
            className="w-74 h-12 shadow-lg rounded bg-white hover:bg-[#004aad] hover:text-white transition-colors duration-300 font-semibold text-lg"
          >
            Build my Open source development
          </button>
        </div>
        <div className="mt-6 md:mt-0">
          <img
            src={web_opn_img_2}
            alt="Open source development"
            className="w-full max-w-lg h-auto"
          />
        </div>
      </div>

      {/* Secondary Image Section */}
      <div className="py-16">
        <div className="flex flex-col md:flex-row justify-center items-center gap-8 px-4">
          <img
            src={web_opn_img_3}
            alt="Development process"
            className="w-full max-w-lg h-auto"
          />
          <p className="text-lg max-w-3xl text-center md:text-left">
            Our commitment to open source tools and frameworks not only provides you with a foundation that adapts to your evolving needs but also supports a collaborative development process that drives efficiency and innovation.
          </p>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Transform Your Online Business with PCL Infotech's Open Source Development
          </h2>
          <p className="text-lg max-w-4xl mx-auto mb-8">
            At PCL Infotech, we're committed to helping you build an e-commerce platform that not only meets your current needs but also supports your future growth.
          </p>
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad]">
            Why Choose PCL Infotech for Your Open Source Development Needs
          </h2>
        </div>

        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[
            {
              title: "Custom Solutions",
              image: custom_solutions,
              description: `We do more than just implement open-source software—we customize and extend it to meet your unique requirements. Whether you need a custom theme, plugin, module, or integration, our solutions align perfectly with your business goals.`
            },
            {
              title: "Cost-Effective Development",
              image: cost_effective_development,
              description: `Open-source software platforms offer a cost-efficient way to develop and maintain digital solutions. We help you maximize this advantage by delivering high-quality, feature-rich solutions without the high costs associated with proprietary software.`
            },
            {
              title: "Community-Driven Innovation",
              image: community_driven_innovation,
              description: `Open source platforms benefit from active communities that continuously enhance their functionality. We stay updated on the latest innovations to ensure your solution remains secure, cutting-edge, and aligned with industry best practices.`
            },
            {
              title: "Scalability and Flexibility",
              image: scalability_and_flexibility,
              description: "As your business evolves, your digital solutions should seamlessly expand to match."
            },
            {
              title: "Security and Stability",
              image: security_and_stability,
              description: `Security is a top priority. We implement best practices and leverage the built-in security features of open-source platforms. Regular updates, patches, and monitoring ensure your solution remains secure and stable.`
            },
            {
              title: "Seamless Integrations",
              image: seamless_integrations_1,
              description: `We specialize in integrating open-source software with third-party tools like CRM systems, payment gateways, and analytics platforms. This ensures smooth interoperability with your existing business infrastructure.`
            },
            {
              title: "Ongoing Support",
              image: maintenance,
              description: `Our continuous support and maintenance services ensure your open-source solution runs smoothly. From troubleshooting and updates to performance optimization, we’re here to ensure your platform remains a valuable business asset.`
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full">
              <img src={feature.image} alt={feature.title} className="w-20 h-20 mb-4" />
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <button
            onClick={openModal}
            className="px-8 py-3 bg-[#004aad] text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            CONTACT US
          </button>
        </div>
      </div>

      {/* Technology Stack Section */}
      <div className="py-16 px-4 bg-gray-50">
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#004aad] mb-4">
            Elevate Your Brand with PCL Infotech's Open Source Development Services
          </h2>
          <p className="text-lg mb-12">
            At PCL Infotech, we believe that great design is about more than just looks—it's about creating a meaningful connection with your audience.
          </p>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-8 items-center justify-items-center">
            {[
              html_logo, css_logo, javascript_logo, react_logo, angular_logo,
              next_logo, express_logo, nodejs_logo, mongo_logo, php_logo,
              mysql_logo, postsql_logo, vs, linux, github
            ].map((src, index) => (
              <img key={index} src={src} alt={`Technology ${index + 1}`} className="w-16 md:w-20 h-auto" />
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={closeModal}></div>

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white w-full max-w-md mx-auto rounded-xl shadow-2xl transform transition-all">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-6 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900">Contact Us</h3>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Modal Content */}
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <label className="text-sm font-medium text-gray-700">Phone Number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700">Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 bg-[#004aad] text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </div>
                </form>

                {isSubmitted && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <p className="text-green-600 text-center font-medium">
                      Thank you! Your message has been sent successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Message Toast (outside modal) */}
      {isSubmitted && !isModalOpen && (
        <div className="fixed bottom-4 right-4 z-50">
          <div className="bg-green-50 text-green-600 px-6 py-4 rounded-lg shadow-lg">
            <p className="font-medium">Thank you! Your message has been sent successfully.</p>
          </div>
        </div>
      )}

    </div>
  );
};

export default OpenSourceDevelopment;